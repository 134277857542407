import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { throwError, Observable } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class PolicyService {
    private API_URL = environment.apiUrl;
    constructor(private http: HttpClient) {}

    // HEADERS
    protected getRequestHeaders(): {
        headers: HttpHeaders | { [header: string]: string | string[] };
    } {
        let headers;
        headers = new HttpHeaders({
            "Content-Type": "application/json",
            "x-api-key": localStorage.getItem("user")
                ? JSON.parse(localStorage.getItem("user")).jwt
                : "",
            //'Access-Control-Allow-Origin': '*'
        });
        return { headers: headers };
    }

    // User Assign Policy
    public getUserPolicyService(Userid, isClient?) {
        let endpoint;
        if (isClient) {
            endpoint = `${this.API_URL}/assigned_policies/policies?user_id=${Userid}`;
        } else {
            endpoint = `${this.API_URL}/policy_pages/${Userid}/policies_by_user`;
        }
        return this.http.get<any>(endpoint, this.getRequestHeaders()).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public assignPolicyToUserService(userId, policy_page) {
        let endpoint = `${this.API_URL}/assigned_policies/assign`;
        let data = {
            user_id: userId,
            policy_page_id: policy_page,
            return_url: `${environment.returnUrl}`,
        };
        return this.http
            .post<any>(endpoint, data, this.getRequestHeaders())
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    public userSelectPackageService(quotesId, eachQuote, additional_message) {
        let endpoint = `${this.API_URL}/quotes/${quotesId}/select`;
        let data = {
            content: eachQuote,
            additional_message: additional_message,
        };
        return this.http
            .post<any>(endpoint, data, this.getRequestHeaders())
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    // Policy Services
    public createUserPolicyService(data) {
        let endpoint = `${this.API_URL}/policy_pages`;
        return this.http
            .post<any>(endpoint, data, this.getRequestHeaders())
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    public updateUserPolicyService(name, policy_pages) {
        let endpoint = `${this.API_URL}/policy_pages/${policy_pages}`;
        let data = { name: name };
        return this.http
            .put<any>(endpoint, data, this.getRequestHeaders())
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    public deleteUserPolicyService(policyId) {
        let endpoint = `${this.API_URL}/policy_pages/${policyId}`;
        return this.http.delete<any>(endpoint, this.getRequestHeaders()).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    // Package Services
    public createUserQuotesService(data) {
        let endpoint = `${this.API_URL}/quotes`;
        return this.http
            .post<any>(endpoint, data, this.getRequestHeaders())
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    public updateUserQuotesService(data, quotes_id) {
        let endpoint = `${this.API_URL}/quotes/${quotes_id}`;
        console.log(data);
        return this.http
            .put<any>(endpoint, data, this.getRequestHeaders())
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    public deleteUserQuotesService(quotesId) {
        let endpoint = `${this.API_URL}/quotes/${quotesId}`;
        return this.http.delete<any>(endpoint, this.getRequestHeaders()).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public setKeyValue(data) {
        const endpoint = `${this.API_URL}/user_items/put`;
        const current_user = JSON.parse(localStorage.getItem("user"));
        const user =
            current_user.user_type == 0
                ? current_user.id
                : JSON.parse(localStorage.getItem("client"));
        let obj = { key: user, data: data };
        return this.http.put<any>(endpoint, obj, this.getRequestHeaders()).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public getKeyValue() {
        const current_user = JSON.parse(localStorage.getItem("user"));
        const user =
            current_user.user_type == 0
                ? current_user.id
                : JSON.parse(localStorage.getItem("client"));

        const endpoint = `${this.API_URL}/user_items/get/${user}`;

        return this.http.get<any>(endpoint, this.getRequestHeaders()).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public getUserAssignedPolicyService(assignedPolicyId) {
        let endpoint = `${this.API_URL}/assigned_policies/${assignedPolicyId}`;
        return this.http.get<any>(endpoint, this.getRequestHeaders()).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }
}
