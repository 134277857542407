import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    ElementRef,
    ViewChild
} from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { HostListener, Input } from "@angular/core";
import {Router} from "@angular/router";
import { AdminAnnouncementService } from '../../admin-announcement.service'
import { HeaderService } from "../../header/header.service";
import { ClientsService } from "./clients.service";

@Component({
  selector: 'app-clients',
  templateUrl: './clients.component.html',
  styleUrls: ['./clients.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class ClientsComponent implements OnInit {

    loginUserDetail:any = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')): '';
    clientList:any = [];


    constructor(
        private clientsService: ClientsService,
        private router: Router,
        private adminAnnouncementService : AdminAnnouncementService,
        private headerService : HeaderService
        ) {
    }

    ngOnInit(): void {
        this.getClientList();
    }

    getClientList(): void {
        this.clientsService.getClientListService().subscribe(
            response => {
                if (response) {
                    if (response['status'] === "ok") {
                        this.clientList = response['users'];
                    }
                }
            },
            error => {
                console.log("error: ", error);
            }
        );
    }

    redirectToUser(eachClient){
        this.clientsService.redirectToUserDataService(eachClient);
        this.router.navigate(['/clients-detail'])
    }


}
