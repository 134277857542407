import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

    loginUserDetail: any = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
    isAdmin: boolean = localStorage.getItem('isAdmin') ? true : false;
    constructor() { }

    ngOnInit() {
    }

}
