import { Component, OnInit, ViewChild, Input, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from "ngx-spinner";
import { AppService } from "src/app/app.service";
import { Subscription } from 'rxjs';
import { AddPostService } from "./add-post.service";
import { UserStructure } from "src/app/common/post-sidebar/user-structure";
import { PostSidebarService } from "src/app/common/post-sidebar/post-sidebar.service";
import { PostListService } from "src/app/common/post-list/post-list.service";

@Component({
  selector: 'app-add-post',
  templateUrl: './add-post.component.html',
  styleUrls: ['./add-post.component.scss']
})
export class AddPostComponent implements OnInit, OnDestroy {
    @ViewChild("CreatePostValue", { static: false }) CreatePostValue;
    @ViewChild("suggestion", { static: false }) suggestion;
    userDetailSubscription: Subscription;
    suggestions = [];
    @Input() calledFromPage;
    @Input() userId: any;
    @Input() user: UserStructure = {
        id: 0,
        name: 'N/A',
        email: 'N/A',
        phone: 'N/A',
        description: 'Working on the latest API integration.',
        avatar_url: '../../../../assets/img/user.png',
        followers: 10200,
        following: 1700,
        location: 'Los Angeles, CA',
        badges: ["Auto", "Home"],
        createdOn: "10/12/2015",
        subscribed: false,
        auto: 'N/A',
        home: 'N/A',
        product: 'N/A',
        user_type: 0,
        join_date: ''
    };
    started: any;
    suggestedusers = [];
    loginUserDetail: any;
    postImageIndex;
    addPostMentionUser = [];
    mentionCounter: number = 0;
    postImage = {
        filename: "",
        content_type: "",
        data: "",
        isImage: "",
    };
    postLoading: boolean = false;
    newPost: string = "";

    constructor(
        private appService: AppService,
        private spinner: NgxSpinnerService,
        private addPostService: AddPostService,
        private postSidebarService: PostSidebarService,
        private postListService: PostListService,
    ) { }

    ngOnInit() {
        this.loginUserDetail = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : "";

        this.postSidebarService.userDetail.subscribe((data: UserStructure) => {
            this.user = data;
        });
    }

    createPost() {
        if (
            this.CreatePostValue.nativeElement.innerHTML ||
            this.postImage.filename != ""
        ) {
            this.postLoading = true;
            this.appService.isBackDrop.next(true);
            this.newPost = this.CreatePostValue.nativeElement.innerHTML;
            // console.log('this.newPost',this.newPost);
            if (
                (this.newPost.length > 1 && this.newPost.trim()) ||
                this.postImage.filename != ""
            ) {
                // this.spinner.show();
                var post_data = {
                    body: this.newPost.trim(),
                    mentions: [],
                    attachments: this.postImage.filename
                        ? [this.postImage]
                        : [],
                };
                this.addPostService.createPost(post_data).subscribe(
                    (res: any) => {
                        this.newPost = "";
                        this.postImage = {
                            filename: "",
                            content_type: "",
                            data: "",
                            isImage: "",
                        };
                        this.CreatePostValue.nativeElement.innerHTML = "";
                        this.addPostMentionUser = [];

                        if (res["status"] == "ok") {
                            this.postLoading = false;
                            this.appService.isBackDrop.next(false);
                            this.postListService.newPostSubmit.next(res["post"]);
                            // this.posts.unshift(res["post"]);
                        }
                    },
                    (error) => {
                        console.log(error);
                        this.postLoading = false;
                        this.spinner.hide();
                        this.appService.isBackDrop.next(false);
                    }
                );
            } else {
                this.postLoading = true;
                this.appService.isBackDrop.next(false);
            }
        }
    }

    uploadFeedImage($event) {
        const file = $event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const date = Date.now();
            if (typeof reader.result === "string") {
                let data = reader.result.split(";");

                let fileType = data[0].split("data:")[1];
                let mimeType = data[0].split("/")[1];
                let base64 = data[1].split("base64,")[1];
                this.postImage = {
                    filename: `${date}.${mimeType}`,
                    content_type: fileType,
                    data: base64,
                    isImage: "",
                };

                if (
                    fileType == "image/png" ||
                    fileType == "image/jpeg" ||
                    fileType == "image/jpg" ||
                    fileType == "image/gif"
                ) {
                    this.postImage.isImage = "image";
                } else {
                    this.postImage.isImage = "file";
                }

                // console.log('this.postImage', this.postImage);
            }
        };
        reader.readAsDataURL(file);
    }

    keypres(event) {
        if (this.suggestion) {
            let rect = event.target.getBoundingClientRect();
            event.target.parentElement.appendChild(
                this.suggestion.nativeElement
            );
        }
    }

    keyup(event) {
        const value = event.target.innerHTML;
        const text = value.substring((value + event.key).lastIndexOf("@") + 1);
        if ([" ", "ENTER"].indexOf(event.key) != -1) {
            this.started = "";
            this.suggestedusers = [];
        }

        if (event.key == "@" || this.started || value.slice(-1) == "@") {
            this.started = event.target;
            if (text.length > 0) {
                this.addPostService
                    .suggestedUsersForMention(
                        this.loginUserDetail.user_type,
                        this.loginUserDetail.id,
                        text
                    )
                    .subscribe((res) => {
                        this.suggestedusers = res["users"];
                    });
            } else {
                this.suggestedusers = [];
            }
        }
    }

    selectUser(user) {
        if (this.started) {
            const value = this.started.innerHTML;
            const text =
                value.substring(0, value.lastIndexOf("@") + 1) +
                `<a contentEditable="false" data-id='${user.id}'>${user.name}</a>`;
            this.started.innerHTML = text;
        }
        this.started = "";
        this.suggestedusers = [];
    }

    ngOnDestroy(){
        if(this.userDetailSubscription != undefined){
            this.userDetailSubscription.unsubscribe();
        }
    }

}
