import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {throwError, Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostListService {

    private API_URL = environment.apiUrl;

    constructor(private http: HttpClient) { }

    public getAllPostObj = new Subject();
    public callPageScrollFun = new Subject();
    public newPostSubmit = new Subject();

    // HEADERS
    protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
        let headers;
        headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).jwt : ''
        //'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    public uploadCommentImage(commentId, data) {
        let endpoint = `${this.API_URL}/comments/${commentId}/attach_image`;

        return this.http.post(endpoint, data, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public uploadPostImage(postId, data) {
        let endpoint = `${this.API_URL}/posts/${postId}/add_image`;

        return this.http.post(endpoint, data, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public getPosts(isAdmin, per_page, page, userId, calledFromPage){
        if(isAdmin){
            var endpoint = `${this.API_URL}/admin/posts`;
        }else if(calledFromPage == 'dashboard'){
            var endpoint = `${this.API_URL}/posts?per_page=${per_page}&page=${page}`;
        }else{
            var endpoint = `${this.API_URL}/posts/${userId}/posts_by_user?per_page=${per_page}&page=${page}`;
        }
        return this.http.get(endpoint, this.getRequestHeaders())
        .pipe(
            catchError(err => {
                return throwError(err);
            })
        )
    }

  public getPostComments(id){
        return this.http.get( `${this.API_URL}/comments/${id}/list`, this.getRequestHeaders())
        .pipe(
            catchError(err => {
                return throwError(err);
            })
        )
    }

    public updatePost(id,data){
        return this.http.put( `${this.API_URL}/posts/${id}`,data, this.getRequestHeaders())
        .pipe(
            catchError(err => {
                return throwError(err);
            })
        )
    }

    public createPost(data) {
        let endpoint = `${this.API_URL}/posts`;
        return this.http.post(endpoint, data, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public addComment(data) {
        let endpoint = `${this.API_URL}/comments`;

        return this.http.post(endpoint, data, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public suggestedUsers(name) {
        let endpoint = `${this.API_URL}/users/name_start_with/${name}`;
        return this.http.get<any>(endpoint, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        );
    }

    public likeUnlikeComment(id, status) {
        let endpoint = `${this.API_URL}/comments/${id}/${status}`;
       return this.http.put(endpoint, {},this.getRequestHeaders())
         .pipe(
           catchError(err => {
             return throwError(err);
           })
         )
    }

    public updateComment(commentId, data) {
        let endpoint = `${this.API_URL}/comments/${commentId}`;

        return this.http.put(endpoint, data, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public deleteComment(commentId) {
        let endpoint = `${this.API_URL}/comments/${commentId}`;

        return this.http.delete(endpoint, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public deleteCommentImage(id) {
        let endpoint = `${this.API_URL}/comments/${id}/delete_image`;

        return this.http.delete(endpoint, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public deletePostImage(index, postId) {
        let endpoint = `${this.API_URL}/posts/${postId}/delete_image`;

        return this.http.put(endpoint, {"index": index} ,this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public markDone(id) {
        let endpoint = `${this.API_URL}/posts/${id}/mark_done`;
        return this.http.put(endpoint, {},this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public markOpen(id) {
        let endpoint = `${this.API_URL}/posts/${id}/mark_open`;
        return this.http.put(endpoint, {},this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public suggestedUsersForMention(user_type, userId, name) {
        if(user_type == 1){
            var endpoint = `${this.API_URL}/users/users_for_mention/${name}?scope=agent`;
        }  else {
            var endpoint = `${this.API_URL}/users/users_for_mention/${name}?client_id=${userId}&scope=client`;
        }
        return this.http.get<any>(endpoint, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        );
    }

    public deletePost(userId) {
        let endpoint = `${this.API_URL}/posts/${userId}`;
        return this.http.delete(endpoint, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public likePost(id) {
        let endpoint = `${this.API_URL}/posts/${id}/like`;
        return this.http.put(endpoint, {}, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public unlikePost(id) {
        let endpoint = `${this.API_URL}/posts/${id}/unlike`;
        return this.http.put(endpoint, {},this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }



}
