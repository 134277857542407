import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';
import { DocumentsService } from "./documents.service";
import { NgxSpinnerService } from "ngx-spinner";
import { HeaderService } from 'src/app/header/header.service';
import { PostListService } from "src/app/common/post-list/post-list.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit, OnDestroy {

    newNotificationSubscription: Subscription;
    uploadImage;
    docList:any = [];
    @Input() userId;
    @Input() isDocUploadFor;

    public files: NgxFileDropEntry[] = [];

    constructor(
        private documentsService: DocumentsService,
        private postListService: PostListService,
        private spinner: NgxSpinnerService,
        private headerService: HeaderService
    ) { }

        ngOnInit() {
            this.getUploadDocList();

            this.newNotificationSubscription = this.headerService.newNotification
            .subscribe(response => {
                this.getUploadDocList();
            })

        }

        public dropped(files: NgxFileDropEntry[]) {
            // https://www.npmjs.com/package/ngx-file-drop
            this.spinner.show();
            this.files = files;
            for (const droppedFile of files) {
              if (droppedFile.fileEntry.isFile) {
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    // console.log('data', file);
                  // Here you can access the real file
                    var myReader:FileReader = new FileReader();
                    myReader.onloadend = (e) => {
                        var result = String(myReader.result);
                        var strImage = result.replace(`data:${file.type};base64,`, "");
                        var data = {
                            "filename" :droppedFile.relativePath,
                            "content-type":file.type,
                            "data" :strImage,
                            "user_id": this.userId
                        }
                        this.documentsService.uploadDocService(data, this.isDocUploadFor)
                        .subscribe(
                            response => {
                                // console.log(response);
                                this.getUploadDocList();
                                this.spinner.hide();
                            },
                            error => {
                                console.log(error);
                                this.spinner.hide();
                            }
                        );
                    }
                    myReader.readAsDataURL(file);
                });
              } else {
                // It was a directory (empty directories are added, otherwise only files)
                const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                // console.log(droppedFile.relativePath, fileEntry);
                this.spinner.hide();
              }
            }
        }

        public fileOver(event){
            // console.log('event in 75', event);
        }

        public fileLeave(event){
            // console.log('event in 80', event);
            // console.log(event);
        }

        formatDate(date) {
            return this.headerService.formatDateFun(date);
        }

        public getUploadDocList() {
            this.spinner.show();
            this.documentsService.getUploadUserDocListService(this.userId)
            .subscribe(
                response => {
                    this.docList = response.documents;
                    // console.log('this.docList', this.docList)
                    this.postListService.getAllPostObj.next();
                    this.spinner.hide();
                },
                error => {
                    console.log(error);
                    this.spinner.hide();
                }
            );
        }

        public deleteDoc(file){
            // console.log('file',file)
            if(confirm('Are you sure want to delete this document?')){
                this.spinner.show();
                this.documentsService.deleteDocService(file.id)
                .subscribe(
                    response => {
                        this.getUploadDocList();
                        this.spinner.hide();
                    },
                    error => {
                        console.log(error);
                        this.spinner.hide();
                    }
                );
            }

        }

        ngOnDestroy(){
            if(this.newNotificationSubscription != undefined){
                this.newNotificationSubscription.unsubscribe();
            }
        }
}
