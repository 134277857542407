import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ContactService {

    private API_URL = environment.apiUrl;

    constructor(private http: HttpClient) {
    }

     // HEADERS
     protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
        let headers;
        headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).jwt : ''
        //'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    public contactUsFormService(data) {
        let endpoint = `${this.API_URL}/contacts`;
        return this.http.post(endpoint, data, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }


}
