import { Component, OnInit } from '@angular/core';
import {SigninService} from './signin.service';
import {Router} from '@angular/router';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  email: string = '';
  password: string = '';
  loading: boolean = false;
  isSignInPage: boolean = true;
  error: string;
  successVal: string = '';

    constructor(private loginService: SigninService, private router: Router) {

    }

    ngOnInit() {
        let check_user_login = localStorage.getItem('user');
        if(check_user_login == undefined || check_user_login == ''){
            localStorage.clear();
        }else{
            this.router.navigate(['/dashboard']);
        }
    }

  loginUser() {
    this.successVal = '';
    this.error = '';
    this.loading = true;
    // calling service to login user
    this.loginService.signinUser({email: this.email, password: this.password})
        .subscribe(response => {
            if (response) {
                if (response.status === 'ok') {
                    this.error = '';
                    localStorage.setItem('user', JSON.stringify(response));
                    if (response.admin){
                        localStorage.setItem('isAdmin', 'true');
                    }
                    this.router.navigate(['/dashboard']);
                } else {
                    this.error = response.message;
                }
                this.loading = false;
            }
        }, error => {
            console.log(error);
        })
  }

  resetformToogle(){
        this.successVal = '';
        this.error = '';
        this.isSignInPage = !this.isSignInPage ;
  }


  resetPassword(){
        this.successVal = '';
        this.error = '';
        this.loginService.resetPassword({email: this.email, return_url: `${environment.returnUrl}/verify_user`})
        .subscribe(response => {
            if (response) {
                if(response.status == "error"){
                    this.error = response.message;
                } else {
                    this.successVal = 'Your reset password request submited successfully';
                    this.email = '';
                }
                // this.router.navigate(['/']);
            }
        }, error => {
            console.log(error);
            this.error = error.message;
        })
  }

}
