import { Injectable } from '@angular/core';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  constructor(private router: Router) { }

  canActivate(): boolean {
    if (!this.isAuthenticated()) {
      this.router.navigate(['signin']);
      return false;
    }
    return true;
  }

  isAuthenticated(): boolean {
    const isAuthenticated = localStorage.getItem("user");
    return !!isAuthenticated;
  }

}
