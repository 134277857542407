import { Component, OnInit } from '@angular/core';
import { ClientsService } from "../../clients/clients.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-loan-officers-list',
  templateUrl: './loan-officers-list.component.html',
  styleUrls: ['./loan-officers-list.component.scss']
})
export class LoanOfficersListComponent implements OnInit {

    userList:any = [];
    userType:number = 5;
    loginUserDetail:any;

    constructor(
        private clientsService: ClientsService,
        private spinner: NgxSpinnerService,
    ) { }

    ngOnInit() {
        this.spinner.show();
        this.loginUserDetail = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')): '';
        this.getUserList();
    }

    getUserList(): void {
        this.clientsService.getUserListService(this.userType).subscribe(
            response => {
                if (response) {
                    if (response['status'] === "ok") {
                        this.userList = response['users'];
                    }
                    // console.log('response', this.clientList);
                }
                this.spinner.hide();
            },
            error => {
                console.log("error: ", error);
                this.spinner.hide();
            }
        );
    }

}
