import { NgbModal, NgbToast } from "@ng-bootstrap/ng-bootstrap";
import { AgentListService } from "src/app/common/agent-list/agent-list.service";
import { Component, OnInit } from "@angular/core";

import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";
import { SignupService } from "src/app/pages/signup/signup.service";

@Component({
    selector: "app-register-client",
    templateUrl: "./register-client.component.html",
    styleUrls: ["./register-client.component.scss"],
})
export class RegisterClientComponent implements OnInit {
    user: any = {
        name: "",
        email: "",
        // username: "",
        phone: "",
        location: "",
    };
    tokenUrl = "";

    mobNumberPattern = "^(d{3})(d{3})(d{4})$";
    emailPattern = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[a-z]{2,4}$";
    loading: boolean = false;
    required: boolean = false;
    error: string = "";
    successVal: string = "";
    public phonemask = [
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
    ];

    constructor(
        private agentListService: AgentListService,
        private router: Router,
        private modalService: NgbModal
    ) {}

    ngOnInit() {}

    closemodal() {
        this.user = {};
        this.modalService.dismissAll();
    }

    onSubmit(form: NgForm) {
        var regexp = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;
        this.successVal = "";
        this.error = "";
        if (form.invalid || !regexp.test(this.user.phone)) {
            this.required = true;
            this.error = "All field must be required";
            return;
        } else {
            this.required = false;
        }

        this.loading = true;
        this.agentListService.registerClient(this.user).subscribe(
            (response) => {
                if (response) {
                    if (response.status === "ok") {
                        form.reset();
                        this.successVal = "Registered Successfully";
                        console.log(response);
                        console.log(this.router);
                        this.tokenUrl =
                            location.origin +
                            "/verify_user?token=" +
                            response.password_token;
                        // this.modalService.dismissAll();
                        // this.router.navigate(["/signin"]);
                    } else {
                        this.error = response.message;
                    }
                    this.loading = false;
                }
            },
            (error) => {
                this.loading = false;
                console.log(error);
            }
        );
    }
}
