import { Component } from "@angular/core";
import { Router, NavigationStart, NavigationEnd } from "@angular/router";
import {
    Location,
    LocationStrategy,
    PathLocationStrategy,
} from "@angular/common";
import { AppService } from './app.service';
declare let $: any;

@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy,
        },
    ],
})
export class AppComponent {
    location: any;
    isBackDrop: boolean = false;

    constructor(private router: Router, location: Location, private appService: AppService) {
        this.router.events.subscribe((ev) => {
            if (ev instanceof NavigationStart) {
                // $('.preloader').fadeIn();
            }
            if (ev instanceof NavigationEnd) {
                this.location = location.path();

                var final_url = this.location.split("?");
                if (final_url.length) {
                    if (final_url[0] == "/verify_user") {
                        this.location = "/verify_user";
                    }
                }

                // $('.preloader').fadeOut('slow');
            }
        });

        this.handleBackdrop()
    }

    handleBackdrop() {
        this.appService.isBackDrop
            .subscribe((response: boolean) => {
                this.isBackDrop = response;
            })
    }
}
