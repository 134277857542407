import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-agent-detail-modal',
  templateUrl: './agent-detail-modal.component.html',
  styleUrls: ['./agent-detail-modal.component.scss']
})
export class AgentDetailModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
