import { Component, OnInit } from '@angular/core';
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {environment} from '../../../environments/environment';


@Component({
  selector: 'app-verify-user',
  templateUrl: './verify-user.component.html',
  styleUrls: ['./verify-user.component.scss']
})
export class VerifyUserComponent implements OnInit {
	 user = {
        password: "",
        confirmpassword: ""
    };
	loading: boolean = false;
    required: boolean = false;
    error: string = "";
    private API_URL = environment.apiUrl;

  constructor(private router: Router,private http: HttpClient) {
  }


  ngOnInit() {
  }

  verify(form: NgForm){
  	if(form.valid){
  			let endpoint = `${this.API_URL}/users/change_password/` + window.location.search.split('=').pop();
		   const obj = {
				"password": this.user.password,
				"password_confirmation": this.user.confirmpassword
			};
		   this.http.put<any>(endpoint, obj)
		      .pipe(
		        catchError(err => {
                    if(err.error.message != undefined){
                        this.error = err.error.message;
                    }
                    return throwError(err);
		        })
		      ).subscribe(res=> {
                  if(res.status == 'error'){
                    // this.error = res.message;
                  }else{
                      this.router.navigate(["/signin"]);
                  }
		      },err=>{
                    if(err.error.message != undefined){
                        this.error = err.error.message;
                    }
		      	// console.error(err);
		      })
  	}

  }
}
