import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { AdminAnnouncementService } from '../admin-announcement.service';


@Component({
  selector: 'app-admin-announcements',
  templateUrl: './admin-announcements.component.html',
  styleUrls: ['./admin-announcements.component.scss']
})
export class AdminAnnouncementsComponent implements OnInit {

    announcementDetail: string = '';

    constructor(
        private adminAnnouncementService : AdminAnnouncementService
    ) { }


    ngOnInit() {

    }

    onDelete() {

    }



}
