// tslint:disable-all-line: quotemark
import { MediaMatcher } from "@angular/cdk/layout";
import { PostSidebarService } from "./post-sidebar.service";
import { NgxSpinnerService } from "ngx-spinner";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute } from "@angular/router";
import { HeaderService } from "src/app/header/header.service";
import { PostListService } from "src/app/common/post-list/post-list.service";
import { UserStructure } from "src/app/common/post-sidebar/user-structure";
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation, ElementRef, EventEmitter,ViewChild, Output, Input } from "@angular/core";

@Component({
  selector: 'app-post-sidebar',
  templateUrl: './post-sidebar.component.html',
  styleUrls: ['./post-sidebar.component.scss']
})
export class PostSidebarComponent implements OnInit, OnDestroy {
    @Input() calledFromPage;
    @Input() userId;
    @Input() isSideBarOpen: boolean = true;

    private _mobileQueryListener: () => void;
    user: UserStructure = {
        id: 0,
        name: 'N/A',
        email: 'N/A',
        phone: 'N/A',
        description: 'Working on the latest API integration.',
        avatar_url: '../../../../assets/img/user.png',
        followers: 10200,
        following: 1700,
        location: 'Los Angeles, CA',
        badges: ["Auto", "Home"],
        createdOn: "10/12/2015",
        subscribed: false,
        auto: 'N/A',
        home: 'N/A',
        product: 'N/A',
        user_type: 0,
        join_date: ''
    };
    mobileQuery: MediaQueryList;
    isEdit: boolean = true;
    isAdmin:boolean;
    isEditJoinDate: boolean = false;
    isSubscribed: boolean = false;
    isAvtarExists: boolean = false;
    loginUserDetail: {
        user_type: number,
        admin: boolean
    };
    isUpdateProfile: boolean = false;
    public phonemask = [
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
    ];
    modalRef;
    homeValue: number = 10;
    productValue: string = "Medical";

    constructor(
        private modalService: NgbModal,
        private changeDetectorRef: ChangeDetectorRef,
        private ef: ElementRef,
        private cd: ChangeDetectorRef,
        private postSidebarService: PostSidebarService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private headerService: HeaderService,
        private route: ActivatedRoute,
        private postListService: PostListService,
        media: MediaMatcher,
    ) {
        this.mobileQuery = media.matchMedia("(max-width: 1240px)");
        this._mobileQueryListener = () => {};
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngOnInit() {
        this.loginUserDetail = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : "";
        if(this.calledFromPage === 'agent'){
            this.isEdit = false;
        } else if(this.calledFromPage === 'client') {
            this.isEdit = true;
        } else if(this.calledFromPage === 'dashboard' && this.loginUserDetail.user_type !== 1 ){
            this.isEdit = false;
            this.isUpdateProfile = true;
        } else {
            this.isEdit = true;
            this.isUpdateProfile = true;
        }

        this.getUserInfo();
    }

    getUserInfo() {
        this.spinner.show();
        this.postSidebarService.userInfo(this.userId).subscribe(
            (response) => {
                if (response) {
                    if (response.status === "ok") {
                        this.user = response.user;
                        if(this.user.avatar_url){
                            this.isAvtarExists = true;
                            this.user.avatar_url = response.user.avatar_url;
                        }else{
                            this.user.avatar_url = "../../../assets/img/user.png";
                        }
                        // update value user
                        this.user.auto =
                            this.user.auto != "" && this.user.auto != null
                                ? this.user.auto
                                : "N/A";
                        this.user.location =
                            this.user.location != "" &&
                            this.user.location != null
                                ? this.user.location
                                : "N/A";
                        this.user.home =
                            this.user.home != "" && this.user.home != null
                                ? this.user.home
                                : "N/A";
                        this.user.product =
                            this.user.product != "" && this.user.product != null
                                ? this.user.product
                                : "N/A";
                        this.isSubscribed = this.user.subscribed;
                        this.postSidebarService.userDetail.next(this.user);
                    }
                    this.spinner.hide();
                }
            },
            (error) => {
                console.log('error', error);
                this.spinner.hide();
            }
        );
    }

    checkSideBar() {
        this.isSideBarOpen = !this.mobileQuery.matches;
        this.changeDetectorRef.detectChanges();
    }

    // sidebar
    toogleSideBar(): void {
        this.isSideBarOpen = !this.isSideBarOpen;
    }

    closeSideBar(event): void {
        event.stopPropagation();
        if (
            this.mobileQuery.matches &&
            this.isSideBarOpen &&
            event.target.contains(this.ef.nativeElement.querySelector("aside"))
        ) {
            this.toogleSideBar();
        }
    }

    editOpen(node) {
        node.disabled = false;
    }

    editOpenChangeDate(val) {
        this.isEditJoinDate = val;
    }

    openDatePopUp(d) {
        d.toggle();
    }

    updateUser(node, text) {
        this.postSidebarService
            .updateUser(this.userId, { [text]: node.value })
            .subscribe((response) => {
                if (response && response["status"] === "ok") {
                    node.disabled = true;
                    this.isEditJoinDate = false;
                    this.user = response["user"];
                    if (response["user"].avatar_url) {
                        this.user.avatar_url = response["user"].avatar_url;
                        this.isAvtarExists = true;
                    } else {
                        this.user.avatar_url = "../../../assets/img/user.png";
                    }
                    this.getUserInfo();
                    if (text == "name") {
                        this.postListService.getAllPostObj.next();
                    }
                    if (text == "join_date") {
                        this.isEditJoinDate = false;
                    }
                }
            });
    }

    uploadAvtar() {
        console.log('upload image');
        let data = {
            userId: this.userId,
            isImageUploadCallFrom: this.calledFromPage
        }
        this.postSidebarService.uploadProfileImage.next(data);
    }

    removeAvtar() {
        this.spinner.show();
        this.postSidebarService.removeAvtar(this.userId).subscribe(
            (response) => {
                if (response) {
                    if (response["status"] === "ok") {
                        this.isAvtarExists = false;
                        this.user.avatar_url = "../../../assets/img/user.png";
                    }
                    this.getUserInfo();
                    let user = JSON.parse(localStorage.getItem("user"));
                    user["avatar_url"] = this.user.avatar_url;
                    localStorage.setItem("user", JSON.stringify(user));
                    this.router
                        .navigateByUrl("/RefreshComponent", {
                            skipLocationChange: true,
                        })
                        .then(() => {
                            if (this.calledFromPage === 'client') {
                                this.router.navigate(["/clients-detail"]);
                            } else {
                                this.router.navigate(["/dashboard"]);
                            }
                        });
                    this.spinner.hide();
                }
                this.postListService.getAllPostObj.next();
                this.spinner.hide();
            },
            (error) => {
                this.spinner.hide();
                console.log(error);
            }
        );
    }

    closeInput(node) {
        node.disabled = true;
        this.getUserInfo();
    }

    editAutoValue(input) {
        input.disabled = false;
    }

    autoValueInputSaveCancel(input) {
        this.getUserInfo();
        input.disabled = true;
    }

    autoValueInputSave(input) {
        // console.log(input.value);
        input.disabled = true;
        this.postSidebarService
            .updateSubscribedService(this.userId, "auto", input.value)
            .subscribe(
                () => {
                    this.getUserInfo();
                },
                (error) => {
                    console.log("\n \n error", error);
                }
            );
    }

    editHomeValue(input) {
        this.getUserInfo();
        input.disabled = false;
    }

    homeValueInputSaveCancel(input) {
        input.disabled = true;
        this.getUserInfo();
    }

    homeValueInputSave(input) {
        input.disabled = true;
        this.postSidebarService
            .updateSubscribedService(this.userId, "home", input.value)
            .subscribe(
                () => {
                    this.getUserInfo();
                },
                (error) => {
                    console.log("\n \n error", error);
                }
            );
    }

    editProduct(productInput) {
        productInput.disabled = false;
    }

    updateSubscribedStatus(subscribe_status) {
        if (subscribe_status) {
            this.postSidebarService
                .updateSubscribedService(this.userId, "subscribed", true)
                .subscribe(() => {
                    this.getUserInfo();
                });
        } else {
            this.postSidebarService
                .updateSubscribedService(this.userId, "subscribed", false)
                .subscribe(() => {
                    this.getUserInfo();
                });
        }
    }

    formatJoinedDate(date) {
        if (date !== null && date !== undefined && date !== '') {
            return new Date(date).toDateString();
        } else {
            return new Date().toDateString();
        }
    }

    ngOnDestroy(){

    }
}
