// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/*export const environment = {
    production: false,
    apiUrl: "http://138.197.69.234",
    apiIP: "138.197.69.234",
    returnUrl: "http://142.93.254.132",
    adminEmail: "admin@insureya.com",
};
*/
 export const environment = {
     production: true,
     apiUrl: "http://api.insureya.com",
     apiIP: "174.138.36.192/api",
     returnUrl: "http://insureya.com",
     adminEmail: "admin@insureya.com",
 };
/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
