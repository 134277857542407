import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {throwError, Observable, Subject} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PostSidebarService {

    private API_URL = environment.apiUrl;
    public userDetail = new Subject();
    public uploadProfileImage = new Subject();

    constructor(private http: HttpClient) { }

    // HEADERS
    protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
        let headers;
        headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).jwt : ''
        //'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    public userInfo(userId) {
        let endpoint = `${this.API_URL}/users/${userId}`;
        return this.http.get<any>(endpoint, this.getRequestHeaders())
        .pipe(
            catchError(err => {
                return throwError(err);
            })
        );
    }

    public updateUser(id, data) {
        let endpoint = `${this.API_URL}/users/${id}`;
        return this.http.put(endpoint,data, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public removeAvtar(userId) {
        let endpoint = `${this.API_URL}/users/${userId}/delete_avatar`;
        return this.http.delete(endpoint, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public uploadAvtar(userId, data) {
        let endpoint = `${this.API_URL}/users/${userId}/upload_avatar`;
        return this.http.post(endpoint, data, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public updateSubscribedService(id, key, value) {
        let endpoint = `${this.API_URL}/users/${id}`;
        return this.http.put(endpoint, {[key] : value},this.getRequestHeaders())
        .pipe(
            catchError(err => {
                return throwError(err);
            })
        )
    }


}
