import { Component, OnInit, Input, Output } from '@angular/core';
import {Router} from "@angular/router";
import { ClientsService } from "../../pages/clients/clients.service";

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {

    @Input() userList:any = [];
    @Input() userType:number = 0;
    loginUserDetail:any;

    constructor(
        private clientsService: ClientsService,
        private router: Router,
    ) { }

    ngOnInit() {
        this.loginUserDetail = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')): '';
    }

    redirectToUser(eachClient){
        console.log('userType', this.userType);
        this.clientsService.redirectToUserDataService(eachClient);
        this.router.navigate(['/clients-detail'])
    }
}
