import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SignupService {

  private API_URL = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  public signupUser(body) {
    let endpoint = `${this.API_URL}/users/register`;
    const obj = {user: body, "return_url" : environment.returnUrl};
    return this.http.post<any>(endpoint, obj, this.getRequestHeaders())
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }


  // HEADERS
  protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
    let headers;
    headers = new HttpHeaders({
      'Content-Type': 'application/json',
      //'Access-Control-Allow-Origin': '*'
    });
    return {headers: headers};
  }

}
