import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class ProfileService {
    private API_URL = environment.apiUrl;

    constructor(private http: HttpClient) {}

    public getUserProfile(username) {
        let endpoint = `${this.API_URL}/users/${username}`;

        return this.http.get(endpoint, this.getRequestHeaders()).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public changePassword(id, data) {
        let endpoint = `${this.API_URL}/users/${id}/update_password`;

        return this.http.post(endpoint, data, this.getRequestHeaders()).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    // HEADERS
    protected getRequestHeaders(): {
        headers: HttpHeaders | { [header: string]: string | string[] };
    } {
        let headers;
        headers = new HttpHeaders({
            "Content-Type": "application/json",
            "x-api-key": localStorage.getItem("user")
                ? JSON.parse(localStorage.getItem("user")).jwt
                : "",
            //'Access-Control-Allow-Origin': '*'
        });
        return { headers: headers };
    }
}
