import { Component, OnInit } from '@angular/core';
import { AdminAnnouncementService } from '../admin-announcement.service';

@Component({
  selector: 'app-create-announcement',
  templateUrl: './create-announcement.component.html',
  styleUrls: ['./create-announcement.component.scss']
})
export class CreateAnnouncementComponent implements OnInit {

  constructor(private annService: AdminAnnouncementService) { }

  ngOnInit() {
  }

  handleSubmit(event:string){
    console.log('got value from announcement form ', event);
    if(event && event!=""){
        this.annService.createAnnouncement(event).subscribe(()=>{
            console.log('Announcement Created !')
        }, (err)=>{
            console.error('Error: while creating announcement ', err);
        })
    }
  }

}
