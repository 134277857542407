import { Component, OnInit } from "@angular/core";
import { SignupService } from "./signup.service";
import { Router } from "@angular/router";
import { NgForm } from "@angular/forms";

@Component({
    selector: "app-signup",
    templateUrl: "./signup.component.html",
    styleUrls: ["./signup.component.scss"]
})
export class SignupComponent implements OnInit {
    user = {
        name: "",
        email: "",
        password: "",
        // username: "",
        phone: ""
    };

    mobNumberPattern = "^(\d{3})(\d{3})(\d{4})$";
    emailPattern = "^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[a-z]{2,4}$";
    loading: boolean = false;
    required: boolean = false;
    error: string = "";
    successVal: string = '';
    public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    constructor(private signupService: SignupService, private router: Router) {
        localStorage.clear();
    }

    ngOnInit() {}

    onSubmit(form: NgForm) {
        var regexp = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;
        this.successVal = "";
        this.error = "";
        if (form.invalid || !regexp.test(this.user.phone)) {
            this.required = true;
            this.error = "All field must be required";
            return;
        } else {
            this.required = false;
        }

        this.loading = true;
        this.signupService.signupUser(this.user).subscribe(
            response => {
                if (response) {
                    if (response.status === "ok") {
                        this.successVal = 'Your account was created successfully. Please check your email and verify it...';
                        form.reset();
                        // this.router.navigate(["/signin"]);
                    } else {
                        this.error = response.message;
                    }
                    this.loading = false;
                }
            },
            error => {
                this.loading = false;
                console.log(error);
            }
        );
    }
}
