import { Component, OnInit } from '@angular/core';
import { PolicyService } from "./policy.service";
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-policy',
  templateUrl: './policy.component.html',
  styleUrls: ['./policy.component.scss']
})
export class PolicyComponent implements OnInit {

    monthlyPolicyFisrtRow:any = [];
    monthlyPolicySecondRow:any = [];
    monthlyPolicyThiredRow:any = [];

    constructor(
        private policyService: PolicyService,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.getUserPolicyList();
    }

    getUserPolicyList(){
        this.policyService.getUserPolicyService(4)
        .subscribe(
            response =>{
                // console.log(response.quote.content);
                this.monthlyPolicyFisrtRow = JSON.parse(response.quote.content);
                this.monthlyPolicySecondRow = JSON.parse(response.quote.content_2);
                this.monthlyPolicyThiredRow = JSON.parse(response.quote.content_3);
                // console.log(this.monthlyPolicyFisrtRow);
            },
            error => {
                console.log(error);
            }
        );
    }

    editInput(inputLable){
        inputLable.disabled = false;
    }

    updateInput(index, type, inputLableFirst){
        // console.log('index', index);
        // console.log('type', type);
        // console.log('value', inputLableFirst.value);
        inputLableFirst.disabled = true;
    }

    createUserPolicyModel(CreatePolicyModel){
        this.modalService.open(CreatePolicyModel, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            // console.log('result', result);
        }, (reason) => {

        });
    }

    createUserQuotesModel(CreateQuotesModel){
        this.modalService.open(CreateQuotesModel, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
            // console.log('result', result);
        }, (reason) => {

        });
    }

    createUserPolicy(){
        let name = '';
        this.policyService.createUserPolicyService(name)
        .subscribe(
            response =>{
                // console.log(response);
            },
            error => {
                console.log(error);
            }
        );
    }

    createUserQuotes(){
        let data ={

        }
        this.policyService.createUserQuotesService(data)
        .subscribe(
            response =>{
                // console.log(response);
            },
            error => {
                console.log(error);
            }
        );
    }

}
