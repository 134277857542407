import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { removeSpaces } from '../../custom-form-validation';
import { ContactService } from "../contact/contact.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

    contactUsForm: FormGroup;
    contactUsFormError:string = '';
    contactUsFormSuccess:string = '';
    public phonemask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

    constructor(
        private formBuilder: FormBuilder,
        private contactService: ContactService,
        private spinner: NgxSpinnerService
    ) { }

    ngOnInit() {
        this.contactUsForm = this.formBuilder.group({
			name: ['', [Validators.required, Validators.minLength(4), removeSpaces]],
            email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"), removeSpaces]],
            // Validators.pattern("^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$")
			phone_number: ['', [Validators.required, removeSpaces]],
			subject: ['', [Validators.required, Validators.minLength(5), removeSpaces]],
			description: ['', [Validators.required, Validators.minLength(10), removeSpaces]]
        });
    }

    get f() {
        return this.contactUsForm.controls;
    }

    contactFormSubmit(){
        this.contactUsFormError = '';
        this.contactUsFormSuccess = '';
        var regexp = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}$/;
        if (this.contactUsForm.invalid || !regexp.test(this.contactUsForm.value.phone_number)){
            this.contactUsFormError = 'All fields must be required and valid.';
        }else{
            this.spinner.show();
            var form_data = {
                "name": this.contactUsForm.value.name,
                "email": this.contactUsForm.value.email,
                "phone" : this.contactUsForm.value.phone_number,
                "subject" : this.contactUsForm.value.subject,
                "description" : this.contactUsForm.value.description
            };

            this.contactService.contactUsFormService(form_data)
            .subscribe(
                response => {
                    this.contactUsFormSuccess = 'Contact us request submitted successfully.';
                    this.spinner.hide();
                    this.contactUsForm.reset();
                },
                error => {
                    console.log(error);
                    this.contactUsFormError = error;
                    this.spinner.hide();
                }
            );
        }
    }

}
