import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DefaultComponent} from './demos/default/default.component';
import {DemoTwoComponent} from './demos/demo-two/demo-two.component';
import {DemoThreeComponent} from './demos/demo-three/demo-three.component';
import {AboutComponent} from './pages/about/about.component';
import {FeaturesComponent} from './pages/features/features.component';
import {TeamComponent} from './pages/team/team.component';
import {PricingComponent} from './pages/pricing/pricing.component';
import {NotFoundComponent} from './pages/not-found/not-found.component';
import {FaqComponent} from './pages/faq/faq.component';
import {BlogComponent} from './pages/blog/blog.component';
import {BlogDetailsComponent} from './pages/blog-details/blog-details.component';
import {SignupComponent} from './pages/signup/signup.component';
import {SigninComponent} from './pages/signin/signin.component';
import {ContactComponent} from './pages/contact/contact.component';
import {DashboardComponent} from './pages/dashboard/dashboard.component';
import {AuthGuardService} from './auth-guard.service';
import {VideoComponent} from './pages/video/video.component';
import { AdminComponent } from './admin/admin.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { VerifyUserComponent } from './pages/verify-user/verify-user.component';
import { PolicyComponent } from './pages/policy/policy.component';
import { ClientsComponent } from './pages/clients/clients.component';
import { AdminAnnouncementsComponent } from './admin-announcements/admin-announcements.component';
import { CreateAnnouncementComponent } from './create-announcement/create-announcement.component';
import { EditAnnouncementComponent } from './edit-announcement/edit-announcement.component';
import { ClientDetailComponent } from './pages/client-detail/client-detail.component';
import { UserPolicyComponent } from './pages/user-policy/user-policy.component';
import { TermConditionComponent } from './pages/term-condition/term-condition.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { UserListComponent } from './common/user-list/user-list.component';
import { ProspectsListComponent } from './pages/_user-list/prospects-list/prospects-list.component';
import { LoanOfficersListComponent } from './pages/_user-list/loan-officers-list/loan-officers-list.component';
import { RealEstateAgentsListComponent } from './pages/_user-list/real-estate-agents-list/real-estate-agents-list.component';
import { CompanyRepsListComponent } from './pages/_user-list/company-reps-list/company-reps-list.component';
import { AdminSigninComponent } from './pages/admin-signin/admin-signin.component';
import { AgentDetailComponent } from './pages/agent-detail/agent-detail.component';

const routes: Routes = [
  {path: '', component: DefaultComponent},
  {path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuardService]},
  {path: 'profile', component: ProfileComponent},
  {path: 'policy', component: PolicyComponent},
  {path: 'term-and-condition', component: TermConditionComponent},
  {path: 'privacy-policy', component: PrivacyPolicyComponent},
  {path: 'demo-2', component: DemoTwoComponent},
  {path: 'demo-3', component: DemoThreeComponent},
  {path: 'about-us', component: AboutComponent},
  {path: 'features', component: FeaturesComponent},
  {path: 'video', component: VideoComponent},
  {path: 'team', component: TeamComponent},
  {path: 'pricing', component: PricingComponent},
  {path: 'faq', component: FaqComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'blog-details', component: BlogDetailsComponent},
  {path: 'signup', component: SignupComponent},
  {path: 'signin', component: SigninComponent},
  {path: 'admin-signin', component: AdminSigninComponent},
  {path: 'contact', component: ContactComponent},
  {path: 'admin', component: AdminComponent},
  {path: 'admin-announcements', component: EditAnnouncementComponent},
   {path: 'create-announcement', component: CreateAnnouncementComponent},
   {path: 'edit-announcements', component: EditAnnouncementComponent},
   {path: 'clients-list', component: ClientsComponent, canActivate: [AuthGuardService]},
   {path: 'prospects-list', component: ProspectsListComponent, canActivate: [AuthGuardService]},
   {path: 'loan-officers-list', component: LoanOfficersListComponent, canActivate: [AuthGuardService]},
   {path: 'real-estate-agents-list', component: RealEstateAgentsListComponent, canActivate: [AuthGuardService]},
   {path: 'company-reps-list', component: CompanyRepsListComponent, canActivate: [AuthGuardService]},
   {path: 'clients-detail', component: ClientDetailComponent, canActivate: [AuthGuardService]},
   {path: 'agent-detail', component: AgentDetailComponent, canActivate: [AuthGuardService]},
   {path: 'user-policy', component: UserPolicyComponent, canActivate: [AuthGuardService]},
   {path: 'verify_user', component: VerifyUserComponent},
   {path: 'error-404', component: NotFoundComponent},
   {path: '**', redirectTo: '/error-404'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
