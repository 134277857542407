import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class SigninService {

  private API_URL = environment.apiUrl;

  constructor(private http: HttpClient) {
  }

  /**
   * calling server to login user
   * @param body body contains username and password
   */

    public signinUser(body) {
        let endpoint = `${this.API_URL}/users/authenticate`;
        return this.http.post<any>(endpoint, body, this.getRequestHeaders())
        .pipe(
            catchError(err => {
                return throwError(err);
            })
        );
    }

  public resetPassword(body) {
      let endpoint = `${this.API_URL}/users/request_password_reset`;
    return this.http.post<any>(endpoint, body)
      .pipe(
        catchError(err => {
          return throwError(err);
        })
      );
  }


  // HEADERS
  protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
    let headers;
    headers = new HttpHeaders({
      'Content-Type': 'application/json',
      //'Access-Control-Allow-Origin': '*'
    });
    return headers;
  }


}
