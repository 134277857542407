import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class AppService {
    isBackDrop = new Subject();
    constructor(private router: Router) {}
}
