import { Component, OnInit } from '@angular/core';
import { HostListener } from "@angular/core";

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit {

  isCallAnswered: boolean = false;
  isSidebarOpened: boolean = false;
  isRightSidebarOpened: boolean = false;
  notification: boolean = false;
  constructor() { }

  ngOnInit(): void {
  }

  answerCall(val) {
    this.isCallAnswered = val;
  }

  openSideBar() {
    this.isSidebarOpened = !this.isSidebarOpened;
  }

  openRightSidebar() {
    this.isRightSidebarOpened = !this.isRightSidebarOpened;
  }
   @HostListener('window:click', ['$event.target'])
      handleKeyDown(event) {
          if(!event.matches('.setting-dropdown, .setting-dropdown *')) {
         this.notification = false;
      } else{
          this.notification = true;

      }
      }
}
