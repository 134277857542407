import { AgentListService } from "./agent-list.service";
import $ from "jquery";
import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    ElementRef,
    ViewChild,
    Input
} from "@angular/core";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-agent-list',
  templateUrl: './agent-list.component.html',
  styleUrls: ['./agent-list.component.scss']
})
export class AgentListComponent implements OnInit, OnDestroy {

    @ViewChild("AgentDetail", { static: false }) AgentDetail;
    agentDetailOnClickByOtherSubscription: Subscription;
    agentList: any = [];
    totalAgentList = 0;
    seeAgentLimitVal;
    eachAgentDetail: any = {};
    loginUserDetail;

    constructor(
        private router: Router,
        private agentListService: AgentListService,
        private modalService: NgbModal,
    ) { }

    ngOnInit() {
        this.someAgentList();
        this.localstorageFun();

        this.agentDetailOnClickByOtherSubscription = this.agentListService.agentDetailOnClickByOther
        .subscribe((data) => {
            this.agentDetailOnClick(data);
        });
    }

    localstorageFun(){
        this.loginUserDetail = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : "";
    }

    seeAllAgentList() {
        this.seeAgentLimitVal = 0;
        this.getAgentListing(this.seeAgentLimitVal);
    }

    someAgentList() {
        this.seeAgentLimitVal = 5;
        this.getAgentListing(this.seeAgentLimitVal);
    }

    getAgentListing(seeAgentLimit) {
        this.agentListService.getAgentListService().subscribe(
            (response) => {
                if (response.status === "ok") {
                    this.totalAgentList = response.total_entries;
                    if (seeAgentLimit != 0) {
                        this.agentList = response.users.slice(0, seeAgentLimit);
                    } else {
                        this.agentList = response.users;
                    }
                    //    this.agentList = [];
                }
            },
            (error) => {
                console.log("error: ", error);
            }
        );
    }

    closeModel(){
        this.modalService.dismissAll(this.modalService);
    }

    agentDetailOnClick(eachAgent) {
        //https://ng-bootstrap.github.io/#/components/modal/examples
        this.localstorageFun();
        this.closeModel();
        // console.log('this.loginUserDetail.user_type', this.loginUserDetail.user_type);
        if(this.loginUserDetail.user_type == 1){
            localStorage.setItem('agent', eachAgent.id);
            // console.log('this.router.url', this.router.url);
            if(this.router.url == '/agent-detail'){
                this.router
                .navigateByUrl("/RefreshComponent", {
                    skipLocationChange: true,
                })
                .then(() => {
                    this.router.navigate(['/agent-detail']);
                });
            }else{
                this.router.navigate(['/agent-detail']);
            }
        }else{
            this.eachAgentDetail = eachAgent;
            this.modalService
                .open(this.AgentDetail, { ariaLabelledBy: "modal-basic-title" })
                .result.then(
                    (result) => {
                        // console.log('result', result);
                    },
                    (reason) => {}
                );
        }
    }

    ngOnDestroy(){
        if(this.agentDetailOnClickByOtherSubscription != undefined){
            this.agentDetailOnClickByOtherSubscription.unsubscribe();
        }
        // this.agentListService.agentDetailOnClickByOther.unsubscribe();
    }

}
