import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

    private API_URL = environment.apiUrl;
    constructor(private http: HttpClient) { }

     // HEADERS
    protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
        let headers;
        headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).jwt : ''
        //'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    public uploadDocService(data, isDocUploadFor) {
        if(isDocUploadFor == "client" || isDocUploadFor == "agent"){
            var endpoint = `${this.API_URL}/documents/agent_upload`;
        }else{
            var endpoint = `${this.API_URL}/documents/upload`;
        }
        return this.http.post(endpoint, data, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public getUploadUserDocListService(userId) {
        let endpoint = `${this.API_URL}/documents/${userId}/documents_by_user`;
        return this.http.get<any>(endpoint, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

    public deleteDocService(docId) {
        let endpoint = `${this.API_URL}/documents/${docId}`;
        return this.http.delete(endpoint, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }
}
