import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { AdminAnnouncementService } from '../../admin-announcement.service';

@Component({
  selector: 'app-announcement-form',
  templateUrl: './announcement-form.component.html',
  styleUrls: ['./announcement-form.component.scss']
})
export class AnnouncementFormComponent implements OnInit {

	announcementForm: FormGroup;
    announcementList:any = {
        "body": "",
        "id": "",
        "enabled": true
    };
    @Output()
    submitted = new EventEmitter();
    @Input()
    buttonText:string;

    constructor(
        private fb: FormBuilder,
        private adminAnnouncementService : AdminAnnouncementService
    ) {}

    ngOnInit() {
        this.announcementForm = this.fb.group({
            'content': [this.announcementList.body, Validators.required]
        })
    }

    onSubmit(){
        const value = this.announcementForm.get('content').value;
        this.submitted.emit(value);
        this.announcementForm.reset();
    }
}
