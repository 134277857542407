import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError, Subject } from "rxjs";
import { environment } from "../../environments/environment";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en";

TimeAgo.addLocale(en);
const timeAgo = new TimeAgo("en-US");

@Injectable({
    providedIn: "root",
})
export class HeaderService {
    private API_URL = environment.apiUrl;
    localStorageVal: any;

    public newNotification = new Subject();
    public findPostForClick = new Subject();
    public findPostOnClick = new Subject();
    public moveOnFeedDiv = new Subject();

    constructor(private http: HttpClient) {
        this.updateLocalStorage();
    }

    updateLocalStorage() {
        this.localStorageVal = localStorage.getItem("user")
            ? JSON.parse(localStorage.getItem("user"))
            : "";
    }

    // HEADERS
    protected getRequestHeaders(): {
        headers: HttpHeaders | { [header: string]: string | string[] };
    } {
        this.updateLocalStorage();
        let headers;
        headers = new HttpHeaders({
            "Content-Type": "application/json",
            "x-api-key": this.localStorageVal.jwt,
            //'Access-Control-Allow-Origin': '*'
        });
        return { headers: headers };
    }

    public notificationListService() {
        let endpoint = `${this.API_URL}/notifications?per_page=1000&page=1`;
        // console.log(endpoint, this.getRequestHeaders());
        return this.http.get<any>(endpoint, this.getRequestHeaders()).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public changeReadStatusNotifyService(notify_id, notify_status) {
        let endpoint = `${this.API_URL}/notifications/${notify_id}`;
        let data = {
            seen: notify_status,
        };
        return this.http
            .put<any>(endpoint, data, this.getRequestHeaders())
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    public clearAllNotifyService() {
        let endpoint = `${this.API_URL}/notifications/clear`;
        return this.http.post<any>(endpoint, {}, this.getRequestHeaders()).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public deleteNotificationService(eachnotifyId) {
        if (this.localStorageVal.admin == true) {
            var endpoint = `${this.API_URL}/admin/notifications/${eachnotifyId}`;
        } else {
            var endpoint = `${this.API_URL}/notifications/${eachnotifyId}`;
        }

        return this.http.delete<any>(endpoint, this.getRequestHeaders()).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public clearWebSocketToken() {
        let endpoint = `${this.API_URL}/users/close_ws`;
        return this.http.get<any>(endpoint, this.getRequestHeaders()).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public getUserId(calledFromPage){
        let userId;
        if (calledFromPage === 'agent') {
            userId = localStorage.getItem('agent') ? JSON.parse(localStorage.getItem('agent')) : '';
        } else if (calledFromPage === 'client') {
            userId = localStorage.getItem('client') ? JSON.parse(localStorage.getItem('client')) : '';
        } else {
            userId = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).id : '';
        }
        return userId;
    }

    public formatDateFun(date){
        if (date !== '' && date !== undefined && date !== null) {
            return timeAgo.format(new Date(date));
        } else  {
            return timeAgo.format(new Date());
        }
    }

}
