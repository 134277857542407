import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { DashboardService } from "../../pages/dashboard/dashboard.service";
import { PolicyService } from "../../pages/policy/policy.service";
import { ClientsService } from "../../pages/clients/clients.service";

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss']
})
export class DefaultComponent implements OnInit {

    assignedPolicyId;
    userDetail;

    constructor(
        private clientsService: ClientsService,
        private dashboardService: DashboardService,
        private policyService: PolicyService,
        private router: Router,
        private route: ActivatedRoute
    ) { }

    ngOnInit() {
        this.userDetail = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : "";
        this.route.queryParams.subscribe(params => {
            this.assignedPolicyId = params['assigned_policy_id'];
        });
        // console.log('this.assignedPolicyId', this.userDetail);

        if(this.assignedPolicyId !== undefined && this.assignedPolicyId !== null && this.userDetail !== null && this.userDetail !== '' && this.userDetail.user_type !== 1){
            localStorage.setItem("client", this.userDetail.id);
            this.getUserAssignedPolicy();
        }
    }

    getUserAssignedPolicy(){
        this.policyService.getUserAssignedPolicyService(this.assignedPolicyId).subscribe(
            res => {
                if(this.userDetail.id == res.assigned_policy.user.id){
                    this.clientsService.redirectToUserDataService(res.assigned_policy.user);
                    // console.log('res', res.assigned_policy.user.id);
                    this.router.navigate(['/user-policy']);
                }
            }, err => {
                console.log('err', err);
            })
    }

}
