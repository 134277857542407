import { Component, OnInit , Input, OnDestroy} from '@angular/core';
import { from, interval, Observable, Subscription } from 'rxjs';
import { DashboardService } from 'src/app/pages/dashboard/dashboard.service';

@Component({
  selector: 'app-announcement-div',
  templateUrl: './announcement-div.component.html',
  styleUrls: ['./announcement-div.component.scss']
})
export class AnnouncementDivComponent implements OnInit, OnDestroy {
  /* Time in milliseconds which decides after how much
   time the rotation of message will be made */
    @Input()
    interval: number;
    interval$: Observable<any>;
    intervalSub$: Subscription;
    currentIndex: number;
    listEmpty: boolean;
    announcements: any[];
    data: string[];
    announcementsValue: string = '';

    constructor(private dashboardService: DashboardService) {
        this.currentIndex = 0;
    }

    ngOnInit() {
        this.listEmpty = true;
        this.dashboardService.getUserAnnoucements().subscribe((announcements: any[]) => {
            this.announcements = announcements.reverse();
            // console.log(this.announcements);
            if(this.announcements.length > 0){
                this.announcementsValue = this.announcements[0].body;
            }
            // this.data = this.announcements.map((val)=>{
            //     return val.body;
            // });
            // now start interval & update currentIndex
            // if(this.data.length > 0){
            //     this.listEmpty = false;
            //     this.interval$ = interval(this.interval);

            // }else{
            //     this.listEmpty = true;
            // }
        }, (err) => {
            console.log('Error getting Annoucements: ', err);
        });


    }

    ngOnDestroy(){
            // unsubscribe to the interval updates
            try{
                // console.log('this.intervalSub$', this.intervalSub$);
                if(this.intervalSub$ != undefined){
                    this.intervalSub$.unsubscribe();
                }
            }catch(e){
                // console.log('Ng OnDestroy', e.message);
            }
    }



}
