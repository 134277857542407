import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddPostService {

    private API_URL = environment.apiUrl;

    constructor(
        private http: HttpClient
    ) { }

     // HEADERS
    protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
        let headers;
        headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).jwt : ''
        //'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    public suggestedUsersForMention(user_type, userId, name) {
        if(user_type == 1){
            var endpoint = `${this.API_URL}/users/users_for_mention/${name}?scope=agent`;
        }  else {
            var endpoint = `${this.API_URL}/users/users_for_mention/${name}?client_id=${userId}&scope=client`;
        }
        return this.http.get<any>(endpoint, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        );
    }

    public createPost(data) {
        let endpoint = `${this.API_URL}/posts`;
        return this.http.post(endpoint, data, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        )
    }

}
