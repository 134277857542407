import { Component, OnInit } from "@angular/core";
import { AdminService } from "./admin.service";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
    selector: "app-admin",
    templateUrl: "./admin.component.html",
    styleUrls: ["./admin.component.scss"],
})
export class AdminComponent implements OnInit {
    constructor(
        private adminService: AdminService,
        private spinner: NgxSpinnerService
    ) {}

    displayedColumns = [
        "avatar_url",
        "username",
        "name",
        "user_type",
        "action",
    ];
    users = [];
    usersTypeList = [];
    current_page: number = 1;
    per_page: number = 10;
    total_items: number = 30;
    click_page: number = 1;

    ngOnInit() {
        this.getUsersTypeList();
    }

    // getUsers() {
    //     this.spinner.show();
    //     this.adminService.getUsers().subscribe(res => {
    //         this.users = res.users;
    //         // console.log(res.users)
    //         this.spinner.hide();
    //     })

    // }

    activateUser(id) {
        this.adminService
            .updateUser(id, {
                is_active: true,
            })
            .subscribe((result) => {
                this.getUsers();
            });
    }

    getUsers() {
        this.spinner.show();
        this.adminService
            .getUsers(this.current_page, this.per_page)
            .subscribe((res) => {
                this.total_items = res.total_entries;
                this.users = res.users.map(function (each_value) {
                    if (each_value.user_type == 0) {
                        each_value.user_type_name = "Client";
                    } else if (each_value.user_type == 1) {
                        each_value.user_type_name = "Agent";
                    } else if (each_value.user_type == 2) {
                        each_value.user_type_name = "Company Reps";
                    } else if (each_value.user_type == 3) {
                        each_value.user_type_name = "Prospects";
                    } else if (each_value.user_type == 4) {
                        each_value.user_type_name = "Real Estate Agent";
                    } else if (each_value.user_type == 5) {
                        each_value.user_type_name = "Loan Officer";
                    }
                    return each_value;
                });
                this.spinner.hide();
                // console.log(this.users)
            });
    }

    pageChanged(event) {
        this.current_page = event;
        this.getUsers();
        // console.log("event", event);
    }

    getUsersTypeList() {
        this.spinner.show();
        this.adminService.getUsersTypeListService().subscribe((res) => {
            this.usersTypeList = res.types;
            this.getUsers();
            this.spinner.hide();
        });
    }

    updateUser(element, event) {
        this.spinner.show();
        // console.log('value', event.target.value);
        // console.log('element', element.id);
        this.adminService
            .updateUser(element.id, { user_type: event.target.value })
            .subscribe((res) => {
                this.getUsers();
                this.spinner.hide();
            });
    }
}
