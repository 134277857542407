import { Component, OnInit, ViewChild, ɵConsole } from "@angular/core";
import { PolicyService } from "../policy/policy.service";
import { ClientDetailService } from "../client-detail/client-detail.service";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Router } from "@angular/router";
import { NgxSpinnerService } from "ngx-spinner";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { removeSpaces } from "../../custom-form-validation";
import { stringify } from "querystring";
import {
    CdkDrag,
    CdkDragMove,
    CdkDropList,
    CdkDropListGroup,
    moveItemInArray,
    CdkDragDrop,
    transferArrayItem,
    CdkDragEnter,
} from "@angular/cdk/drag-drop";
import { ViewportRuler } from "@angular/cdk/overlay";
declare var $;
@Component({
    selector: "app-user-policy",
    templateUrl: "./user-policy.component.html",
    styleUrls: ["./user-policy.component.scss"],
})
export class UserPolicyComponent implements OnInit {
    userId;
    dragStartIndex;
    items = [
        {
            width: "33%",
            val: 1,
        },
        {
            width: "33%",
            val: 2,
        },
        {
            width: "33%",
            val: 3,
        },
        {
            width: "33%",
            val: 4,
        },
    ];
    loginUserDetail: any = localStorage.getItem("user")
        ? JSON.parse(localStorage.getItem("user"))
        : "";

    userDetail: {
        id: number;
        name: string;
    } = {
        id: 0,
        name: "",
    };

    quotesList: {
        id: number;
        name: string;
        content:
            | string
            | {
                  most_popular: string;
                  price: string;
                  period: string;
                  list: { key: string; value: string }[];
                  Add_Ons: {
                      key: string;
                      amount: string;
                      peroid: string;
                      status: boolean;
                  }[];
              }[];
    }[] = [
        {
            id: 1,
            name: "a",
            content: [
                {
                    most_popular: "a",
                    price: "a",
                    period: "a",
                    list: [
                        {
                            key: "a",
                            value: "a",
                        },
                    ],
                    Add_Ons: [
                        {
                            key: "a",
                            amount: "a",
                            peroid: "a",
                            status: false,
                        },
                    ],
                },
            ],
        },
    ];
    tempEachData = "";
    policyResponse: {
        id: number;
        name: string;
        created_at: string;
        packages: {
            id: number;
            name: string;
            content:
                | string
                | {
                      most_popular: string;
                      price: string;
                      period: string;
                      list: { key: string; value: string }[];
                      Add_Ons: {
                          key: string;
                          amount: string;
                          peroid: string;
                          status: boolean;
                      }[];
                  }[];
        }[];
    }[] = [
        {
            id: 1,
            name: "",
            created_at: "",
            packages: [
                {
                    id: 1,
                    name: "a",
                    content: [
                        {
                            most_popular: "a",
                            price: "a",
                            period: "a",
                            list: [
                                {
                                    key: "a",
                                    value: "a",
                                },
                            ],
                            Add_Ons: [
                                {
                                    key: "a",
                                    amount: "a",
                                    peroid: "a",
                                    status: false,
                                },
                            ],
                        },
                    ],
                },
            ],
        },
    ];

    userPolicyFormError: string = "";
    userPolicyFormSuccess: string = "";
    userQuotesFormError: string = "";
    userQuotesFormSuccess: string = "";
    userPolicyUpdateFormError: string = "";
    userPolicyUpdateFormSuccess: string = "";
    userPolicyForm: FormGroup;
    userPolicyUpdateForm: FormGroup;
    userQuotesForm: FormGroup;
    userHomeQuotesForm: FormGroup;
    userHomeQuotesUpdateForm: FormGroup;
    userQuotesUpdateForm: FormGroup;
    userHomeQuotesFormError: string = "";
    userHomeQuotesFormSuccess: string = "";
    isPolicyAdded: boolean = false;
    agentPermissionStatus;
    userPolicyIndexCount = 0;
    modalRef;
    showAutoForm: boolean = true;
    showHomeForm: boolean = false;
    showUpdateAutoForm: boolean = true;
    showUpdatHomeForm: boolean = false;
    devicesIndex: any[] = [];

    @ViewChild(CdkDropListGroup, { static: false }) listGroup: CdkDropListGroup<
        CdkDropList
    >;
    @ViewChild(CdkDropList, { static: false }) placeholder: CdkDropList;

    public target: CdkDropList;
    public targetIndex: number;
    public source: CdkDropList;
    public sourceIndex: number;
    public activeContainer;
    index: number = 0;
    private indexDetector: number = 1;
    policyIndex: number = -1;

    constructor(
        private clientDetailService: ClientDetailService,
        private policyService: PolicyService,
        private router: Router,
        private spinner: NgxSpinnerService,
        private formBuilder: FormBuilder,
        private modalService: NgbModal,
        private viewportRuler: ViewportRuler
    ) {
        if (this.loginUserDetail.user_type == 0) {
            // console.log('this.loginUserDetail.id',this.loginUserDetail.id);
            this.userId = this.loginUserDetail.id;
        } else {
            this.userId = localStorage.getItem("client");
        }
        this.agentPermissionStatus =
            this.loginUserDetail.user_type == 1 ||
            this.loginUserDetail.admin == true
                ? true
                : false;
    }

    get f() {
        return this.userPolicyForm.controls;
    }

    get u() {
        return this.userQuotesForm.controls;
    }

    get h() {
        return this.userPolicyUpdateForm.controls;
    }

    get j() {
        return this.userHomeQuotesForm.controls;
    }

    get s() {
        return this.userQuotesUpdateForm.controls;
    }

    get k() {
        return this.userHomeQuotesUpdateForm.controls;
    }

    entered(event: CdkDragEnter, arr, index) {
        this.dragStartIndex = index;
        moveItemInArray(arr, event.item.data, event.container.data);
        // this.policyResponse[index].packages = arr.packages;
        // this.setKeyValue();
    }

    ngOnInit() {
        if (this.userId != undefined && this.userId != "") {
            this.getUserInfo();
            this.getUserPolicyList(this.userId);
        } else {
            this.router.navigate(["/dashboard"]);
        }

        this.userPolicyForm = this.formBuilder.group({
            name: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
        });

        this.userPolicyUpdateForm = this.formBuilder.group({
            policyId: ["", [Validators.required]],
            name: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
        });

        this.userQuotesForm = this.formBuilder.group({
            policyType: ["", [Validators.required]],
            policyId: ["", [Validators.required]],
            packageName: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            price: [
                "",
                [
                    Validators.required,
                    Validators.pattern(/^\d+\.{0,1}\d{0,4}$/),
                ],
            ],
            bodilyInjury: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            propertyDamage: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            um: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            collisionDeductable: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            comprehensive: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            policyLength: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            note: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            rentalReimbursement: [
                "",
                [
                    Validators.required,
                    Validators.pattern("^((\\+91-?)|0)?[0-9]{1,9}$"),
                ],
            ],
            roadsideAssistance: [
                "",
                [
                    Validators.required,
                    Validators.pattern("^((\\+91-?)|0)?[0-9]{1,9}$"),
                ],
            ],
        });

        this.userQuotesUpdateForm = this.formBuilder.group({
            quotesId: ["", [Validators.required]],
            packageName: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            price: [
                "",
                [
                    Validators.required,
                    Validators.pattern(/^\d+\.{0,1}\d{0,4}$/),
                ],
            ],
            bodilyInjury: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            propertyDamage: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            um: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            collisionDeductable: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            comprehensive: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            policyLength: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            note: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            rentalReimbursement: [
                "",
                [
                    Validators.required,
                    Validators.pattern("^((\\+91-?)|0)?[0-9]{1,9}$"),
                ],
            ],
            roadsideAssistance: [
                "",
                [
                    Validators.required,
                    Validators.pattern("^((\\+91-?)|0)?[0-9]{1,9}$"),
                ],
            ],
        });

        this.userHomeQuotesForm = this.formBuilder.group({
            policyType: ["", [Validators.required]],
            policyId: ["", [Validators.required]],
            packageName: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            dwellingCoverage: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            price: [
                "",
                [
                    Validators.required,
                    Validators.pattern(/^\d+\.{0,1}\d{0,4}$/),
                ],
            ],
            otherStructures: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            personalProperty: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            lossOfUse: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            medPay: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            deductible: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            policyLength: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            note: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            gunCoverage: ["", [Validators.required]],
            jewelleryCoverage: ["", [Validators.required]],
            collectionCoverage: ["", [Validators.required]],
        });

        this.userHomeQuotesUpdateForm = this.formBuilder.group({
            quotesId: ["", [Validators.required]],
            packageName: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            dwellingCoverage: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            price: [
                "",
                [
                    Validators.required,
                    Validators.pattern(/^\d+\.{0,1}\d{0,4}$/),
                ],
            ],
            otherStructures: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            personalProperty: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            lossOfUse: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            medPay: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            deductible: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            policyLength: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            note: [
                "",
                [Validators.required, Validators.minLength(2), removeSpaces],
            ],
            gunCoverage: ["", [Validators.required]],
            jewelleryCoverage: ["", [Validators.required]],
            collectionCoverage: ["", [Validators.required]],
        });
    }

    getUserInfo() {
        this.quotesList = [];
        this.spinner.show();
        this.clientDetailService.userInfo(this.userId).subscribe(
            (response) => {
                if (response) {
                    if (response.status === "ok") {
                        this.userDetail = response.user;
                        this.quotesList = [];
                        // console.log('response', this.userDetail);
                        this.spinner.hide();
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    getUserPolicyList(userId, setValue?) {
        this.spinner.show();
        this.policyResponse = [];
        this.policyService
            .getUserPolicyService(userId, this.loginUserDetail.user_type == 0)
            .subscribe(
                (response) => {
                    // console.log('response in 200', response.policy_pages);
                    if (response.policy_pages.length > 0) {
                        this.isPolicyAdded = true;
                        const policies = response.policy_pages.map((policy) => {
                            policy.packages = policy.packages.map((pack) => {
                                pack.content = JSON.parse(pack.content);
                                return pack;
                            });
                            return policy;
                        });
                        this.policyResponse = policies;
                        // console.log(this.policyResponse);
                        if (setValue) {
                            this.setKeyValue(true);
                        } else {
                            this.getKeyValue();
                        }

                        // console.log('response in 215' ,policy);
                        this.spinner.hide();
                    } else {
                        this.policyResponse = [];
                        this.isPolicyAdded = false;
                        this.spinner.hide();
                    }
                },
                (error) => {
                    console.log(error);
                    this.policyResponse = [];
                    this.spinner.hide();
                }
            );
    }

    editInput(inputLable) {
        inputLable.disabled = false;
    }

    updateInput(inputLable, policyIndex, type, index) {
        this.spinner.show();
        inputLable.disabled = true;
        if (type == "price") {
            this.quotesList[`${policyIndex}`].content.price = inputLable.value;
        }

        if (type == "list") {
            this.quotesList[`${policyIndex}`].content.list[`${index}`].value =
                inputLable.value;
        }
        if (type == "add_on") {
            if (
                this.quotesList[`${policyIndex}`].content.Add_Ons[`${index}`]
                    .amount
            ) {
                this.quotesList[`${policyIndex}`].content.Add_Ons[
                    `${index}`
                ].amount = inputLable.value;
            } else if (
                this.quotesList[`${policyIndex}`].content.Add_Ons[`${index}`]
                    .value
            ) {
                this.quotesList[`${policyIndex}`].content.Add_Ons[
                    `${index}`
                ].value = inputLable.value;
            }
        }

        if (type == "checkbox") {
            this.quotesList[`${policyIndex}`].content.Add_Ons[
                `${index}`
            ].status = inputLable.checked;
        }
        var id = this.quotesList[`${policyIndex}`].id;
        let temp = this.quotesList[policyIndex];
        temp.content = JSON.stringify(temp.content);
        this.policyService.updateUserQuotesService(temp, id).subscribe(
            (response) => {
                // console.log('response in 134', response);
                this.getUserPolicyList(this.userId);
                this.spinner.hide();
            },
            (error) => {
                console.log(error);
                this.spinner.hide();
            }
        );
    }

    noReturnPredicate(drag: CdkDrag, drop: CdkDropList) {
        return false;
    }

    drop(event: CdkDragDrop<string[]>, index) {
        if (this.dragStartIndex === index) {
            this.setKeyValue();
        }
    }

    updateInputCheckBox(inputLable, packageIndex, type, index, policyIndex) {
        this.policyResponse[`${policyIndex}`].packages[
            `${packageIndex}`
        ].content.Add_Ons[`${index}`].status = inputLable.checked;
        // console.log('this.policyResponse', this.policyResponse);
        // this.spinner.show();
    }

    createUserPolicyModel(CreatePolicyModel) {
        // console.log('CreatePolicyModel', CreatePolicyModel);
        this.userPolicyFormError = "";
        this.userPolicyFormSuccess = "";
        this.modalService
            .open(CreatePolicyModel, { ariaLabelledBy: "modal-basic-title" })
            .result.then(
                (result) => {
                    // console.log('-- result', result);
                },
                (reason) => {
                    // console.log('-- reason', reason);
                }
            );
    }

    changePolicyForm($event) {
        // console.log("$event.target.value", $event.target.value);
        if ($event.target.value == "Home") {
            this.showAutoForm = false;
            this.showHomeForm = true;
            this.userHomeQuotesForm.controls["policyType"].setValue("Home");
            // this.userHomeQuotesForm.patchValue({ policyType: "Home" });
        } else {
            this.showAutoForm = true;
            this.showHomeForm = false;
            this.userQuotesForm.controls["policyType"].setValue("Auto");
        }
    }

    createUserQuotesModel(CreateQuotesModel) {
        this.userQuotesFormError = "";
        this.userQuotesFormSuccess = "";

        this.showAutoForm = true;
        this.showHomeForm = false;
        this.modalRef = this.modalService
            .open(CreateQuotesModel, { ariaLabelledBy: "modal-basic-title" })
            .result.then(
                (result) => {
                    // console.log('--result', result);
                },
                (reason) => {
                    // console.log(' -- reason', reason);
                }
            );
    }

    createUserPolicy() {
        this.userPolicyFormError = "";
        this.userPolicyFormSuccess = "";
        if (this.userPolicyForm.invalid) {
            this.userPolicyFormError =
                "Name fields must be required and valid.";
        } else {
            this.spinner.show();
            var form_data = {
                name: this.userPolicyForm.value.name,
                user_id: this.userId,
            };
            // console.log(form_data);
            this.policyService.createUserPolicyService(form_data).subscribe(
                (response) => {
                    if (response.policy_page.id) {
                        this.devicesIndex.push({
                            id: response.policy_page.id,
                            data: [],
                        });
                        this.policyService
                            .assignPolicyToUserService(
                                this.userId,
                                response.policy_page.id
                            )
                            .subscribe(
                                (response) => {
                                    this.userPolicyForm.reset();
                                    this.spinner.hide();
                                    this.getUserPolicyList(this.userId);
                                    this.userPolicyFormSuccess =
                                        "User's policy created successfully.";
                                    this.modalService.dismissAll(this.modalRef);
                                },
                                (error) => {
                                    console.log(error);
                                    this.userPolicyFormError = error;
                                    this.spinner.hide();
                                }
                            );
                    }
                },
                (error) => {
                    console.log(error);
                    this.userPolicyFormError = error;
                    this.spinner.hide();
                }
            );
        }
    }

    createUserQuotes() {
        this.userQuotesFormError = "";
        this.userQuotesFormSuccess = "";
        if (this.userQuotesForm.invalid) {
            this.userQuotesFormError = "All fields are required and valid.";
        } else {
            this.spinner.show();
            var form_data = {
                policy_page_id: this.userQuotesForm.value.policyId,
                name: this.userQuotesForm.value.packageName,
                enabled: true,
                content: JSON.stringify({
                    policy_type: this.userQuotesForm.value.policyType,
                    most_popular: false,
                    price: this.userQuotesForm.value.price,
                    period: "mo",
                    list: [
                        {
                            key: "Bodily Injury",
                            value: this.userQuotesForm.value.bodilyInjury,
                        },
                        {
                            key: "Property Damage",
                            value: this.userQuotesForm.value.propertyDamage,
                        },
                        {
                            key: "UM/UIM",
                            value: this.userQuotesForm.value.um,
                        },
                        {
                            key: "Collision Deductable",
                            value: this.userQuotesForm.value
                                .collisionDeductable,
                        },
                        {
                            key: "Comp Deductible",
                            value: this.userQuotesForm.value.comprehensive,
                        },
                        {
                            key: "Policy Length",
                            value: this.userQuotesForm.value.policyLength,
                        },
                        {
                            key: "Note",
                            value: this.userQuotesForm.value.note,
                        },
                    ],
                    Add_Ons: [
                        {
                            key: "Rental Reimbursement",
                            amount: this.userQuotesForm.value
                                .rentalReimbursement,
                            peroid: "mo",
                            status: false,
                        },
                        {
                            key: "Roadside Assistance",
                            amount: this.userQuotesForm.value
                                .roadsideAssistance,
                            peroid: "mo",
                            status: false,
                        },
                    ],
                }),
            };
            // console.log(form_data);
            this.policyService.createUserQuotesService(form_data).subscribe(
                (response) => {
                    if (response) {
                        this.userQuotesForm.reset();
                        this.spinner.hide();
                        this.getUserPolicyList(this.userId, true);
                        this.userQuotesFormSuccess =
                            "User's package created successfully.";
                        this.modalService.dismissAll(this.modalRef);
                        this.userQuotesForm.patchValue({
                            policyType: "",
                            policyId: "",
                        });
                        this.userHomeQuotesForm.patchValue({
                            policyType: "",
                            policyId: "",
                        });
                    }
                },
                (error) => {
                    console.log(error);
                    this.userQuotesFormError = error;
                    this.spinner.hide();
                }
            );
        }
    }

    createHomeUserQuotes() {
        this.userHomeQuotesFormError = "";
        this.userHomeQuotesFormSuccess = "";
        if (this.userHomeQuotesForm.invalid) {
            this.userQuotesFormError = "All fields must be required and valid.";
        } else {
            this.spinner.show();
            var form_data = {
                policy_type: this.userHomeQuotesForm.value.policyType,
                policy_page_id: this.userHomeQuotesForm.value.policyId,
                name: this.userHomeQuotesForm.value.packageName,
                enabled: true,
                content: JSON.stringify({
                    policy_type: "home",
                    most_popular: false,
                    price: this.userHomeQuotesForm.value.price,
                    period: "yr",
                    list: [
                        {
                            key: "Dwelling Coverage",
                            value: this.userHomeQuotesForm.value
                                .dwellingCoverage,
                        },
                        {
                            key: "Other Structures",
                            value: this.userHomeQuotesForm.value
                                .otherStructures,
                        },
                        {
                            key: "Personal Property",
                            value: this.userHomeQuotesForm.value
                                .personalProperty,
                        },
                        {
                            key: "Personal Liability",
                            value: this.userHomeQuotesForm.value.lossOfUse,
                        },
                        {
                            key: "Med Pay",
                            value: this.userHomeQuotesForm.value.medPay,
                        },
                        {
                            key: "Deductible",
                            value: this.userHomeQuotesForm.value.deductible,
                        },
                        {
                            key: "Policy Length",
                            value: this.userHomeQuotesForm.value.policyLength,
                        },
                        {
                            key: "Note",
                            value: this.userHomeQuotesForm.value.note,
                        },
                    ],
                    Add_Ons: [
                        {
                            key: "Gun Coverage",
                            value: this.userHomeQuotesForm.value.gunCoverage,
                            peroid: "yr",
                            status: false,
                        },
                        {
                            key: "Jewelry Coverage",
                            value: this.userHomeQuotesForm.value
                                .jewelleryCoverage,
                            peroid: "yr",
                            status: false,
                        },
                        {
                            key: "Collection Coverage",
                            value: this.userHomeQuotesForm.value
                                .collectionCoverage,
                            peroid: "yr",
                            status: false,
                        },
                    ],
                }),
            };
            // console.log(form_data);
            this.policyService.createUserQuotesService(form_data).subscribe(
                (response) => {
                    if (response) {
                        // console.log(
                        //     "this.userHomeQuotesForm.value.policyId",
                        //     this.userHomeQuotesForm.value.policyId
                        // );
                        const index = this.devicesIndex.findIndex(
                            (x) =>
                                x.id == this.userHomeQuotesForm.value.policyId
                        );
                        this.userHomeQuotesForm.reset();
                        this.userHomeQuotesForm.controls["policyType"].setValue(
                            ""
                        );
                        this.spinner.hide();
                        this.getUserPolicyList(this.userId, true);
                        this.userQuotesFormSuccess =
                            "User's package created successfully.";
                        this.modalService.dismissAll(this.modalRef);
                        this.userQuotesForm.patchValue({
                            policyType: "",
                            policyId: "",
                        });
                        this.userHomeQuotesForm.patchValue({
                            policyType: "",
                            policyId: "",
                        });
                    }
                },
                (error) => {
                    console.log(error);
                    this.userQuotesFormError = error;
                    this.spinner.hide();
                }
            );
        }
    }

    deletePackageAgent(quotesId, policyId) {
        if (confirm("Are you sure want delete this package?")) {
            this.spinner.show();
            this.policyService.deleteUserQuotesService(quotesId).subscribe(
                (response) => {
                    if (response) {
                        this.spinner.hide();
                        this.getUserPolicyList(this.userId, true);
                    }
                },
                (error) => {
                    console.log(error);
                    this.spinner.hide();
                }
            );
        }
    }

    requestPackageUser(quotesId, policyIndex, packageIndex) {
        if (confirm("Are you sure want to select this package?")) {
            this.spinner.show();
            var eachQuote = this.policyResponse[`${policyIndex}`].packages[
                `${packageIndex}`
            ];

            var temp_list = eachQuote.content.list;
            var add_ons = eachQuote.content.Add_Ons;

            var list: string = "package <br> <ul>";

            for (var i = 0; i < temp_list.length; i++) {
                list =
                    list +
                    `<li><b>${temp_list[i].key}:</b> ${temp_list[i].value}</li>`;
            }
            let str = "";

            for (let i = 0; i < add_ons.length; i++) {
                if (add_ons[i].status) {
                    str =
                        str +
                        `<li><b>${add_ons[i].key}:</b> ${add_ons[i].amount || add_ons[i].value}/${add_ons[i].peroid}</li>`;
                }
            }

            if (str) {
                list = list + "<br> Add-Ons <ul>" + str + "</ul>";
            }

            list = list + "</ul>";
            var additional_message = list;

            var finalVal = {
                content: JSON.stringify(eachQuote.content),
            };

            this.policyService
                .userSelectPackageService(
                    quotesId,
                    finalVal,
                    additional_message
                )
                .subscribe(
                    (response) => {
                        this.spinner.hide();
                        // this.getUserPolicyList(this.userId);
                        this.router.navigate(["/dashboard"]);
                    },
                    (error) => {
                        console.log(error);
                        this.spinner.hide();
                    }
                );
        }
    }

    editPackageAgent(UpdateQuotesModel, quotesId, index, policyIndex) {
        // console.log(this.policyResponse);
        var eachQuote = this.policyResponse[`${policyIndex}`].packages[
            `${index}`
        ];

        // console.log('eachQuote', eachQuote);
        this.userPolicyUpdateFormError = "";
        this.userPolicyUpdateFormSuccess = "";
        console.log(eachQuote.content);
        if (eachQuote.content.policy_type == "home") {
            this.showUpdateAutoForm = false;
            this.showUpdatHomeForm = true;
            this.userHomeQuotesUpdateForm.patchValue({
                quotesId: eachQuote.id,
                packageName: eachQuote.name,
                price: eachQuote.content.price,
                dwellingCoverage: eachQuote.content.list[0].value,
                otherStructures: eachQuote.content.list[1].value,
                personalProperty: eachQuote.content.list[2].value,
                lossOfUse: eachQuote.content.list[3].value,
                medPay: eachQuote.content.list[4].value,
                deductible: eachQuote.content.list[5].value,
                policyLength: eachQuote.content.list[6].value,
                note: eachQuote.content.list[7].value,
                gunCoverage: eachQuote.content.Add_Ons[0].value,
                jewelleryCoverage: eachQuote.content.Add_Ons[1].value,
                collectionCoverage: eachQuote.content.Add_Ons[0].value,
            });
        } else {
            this.showUpdateAutoForm = true;
            this.showUpdatHomeForm = false;
            this.userQuotesUpdateForm.patchValue({
                quotesId: eachQuote.id,
                packageName: eachQuote.name,
                price: eachQuote.content.price,
                bodilyInjury: eachQuote.content.list[0].value,
                propertyDamage: eachQuote.content.list[1].value,
                um: eachQuote.content.list[2].value,
                collisionDeductable: eachQuote.content.list[3].value,
                comprehensive: eachQuote.content.list[4].value,
                policyLength: eachQuote.content.list[5].value,
                note: eachQuote.content.list[6].value,
                rentalReimbursement: eachQuote.content.Add_Ons[0].amount,
                roadsideAssistance: eachQuote.content.Add_Ons[1].amount,
            });
        }

        this.modalRef = this.modalService
            .open(UpdateQuotesModel, { ariaLabelledBy: "modal-basic-title" })
            .result.then(
                (result) => {
                    // console.log('-- result', result);
                },
                (reason) => {
                    // console.log('-- reason', reason);
                }
            );
    }

    updateUserQuotes() {
        // console.log('this.userQuotesUpdateForm.value', this.userQuotesUpdateForm.value);
        this.userPolicyUpdateFormError = "";
        this.userPolicyUpdateFormSuccess = "";
        if (this.userQuotesUpdateForm.invalid) {
            this.userPolicyUpdateFormError =
                "All fields must be required and valid.";
        } else {
            this.spinner.show();
            var form_data = {
                // "policy_page_id":this.policyResponse.id,
                name: this.userQuotesUpdateForm.value.packageName,
                enabled: true,
                content: JSON.stringify({
                    policy_type: "auto",
                    most_popular: false,
                    price: this.userQuotesUpdateForm.value.price,
                    period: "mo",
                    list: [
                        {
                            key: "Bodily Injury",
                            value: this.userQuotesUpdateForm.value.bodilyInjury,
                        },
                        {
                            key: "Property Damage",
                            value: this.userQuotesUpdateForm.value
                                .propertyDamage,
                        },
                        {
                            key: "UM/UIM",
                            value: this.userQuotesUpdateForm.value.um,
                        },
                        {
                            key: "Collision Deductable",
                            value: this.userQuotesUpdateForm.value
                                .collisionDeductable,
                        },
                        {
                            key: "Comp Deductible",
                            value: this.userQuotesUpdateForm.value
                                .comprehensive,
                        },
                        {
                            key: "Policy Length",
                            value: this.userQuotesUpdateForm.value.policyLength,
                        },
                        {
                            key: "Note",
                            value: this.userQuotesUpdateForm.value.note,
                        },
                    ],
                    Add_Ons: [
                        {
                            key: "Rental Reimbursement",
                            amount: this.userQuotesUpdateForm.value
                                .rentalReimbursement,
                            peroid: "mo",
                            status: false,
                        },
                        {
                            key: "Roadside Assistance",
                            amount: this.userQuotesUpdateForm.value
                                .roadsideAssistance,
                            peroid: "mo",
                            status: false,
                        },
                    ],
                }),
            };
            // console.log(form_data);
            this.policyService
                .updateUserQuotesService(
                    form_data,
                    this.userQuotesUpdateForm.value.quotesId
                )
                .subscribe(
                    (response) => {
                        if (response) {
                            this.userQuotesUpdateForm.reset();
                            this.spinner.hide();
                            this.getUserPolicyList(this.userId);
                            this.userPolicyUpdateFormSuccess =
                                "User's package updated successfully.";
                            this.modalService.dismissAll(this.modalRef);
                        }
                    },
                    (error) => {
                        console.log(error);
                        this.userPolicyUpdateFormError = error;
                        this.spinner.hide();
                    }
                );
        }
    }

    updateUserHomeQuotes() {
        // console.log(
        //     "this.userHomeQuotesUpdateForm.value",
        //     this.userHomeQuotesUpdateForm.value
        // );
        this.userPolicyUpdateFormError = "";
        this.userPolicyUpdateFormSuccess = "";
        if (this.userHomeQuotesUpdateForm.invalid) {
            this.userPolicyUpdateFormError =
                "All fields must be required and valid.";
        } else {
            this.spinner.show();
            var form_data = {
                policy_page_id: this.userHomeQuotesUpdateForm.value.policyId,
                name: this.userHomeQuotesUpdateForm.value.packageName,
                enabled: true,
                content: JSON.stringify({
                    policy_type: "home",
                    most_popular: false,
                    price: this.userHomeQuotesUpdateForm.value.price,
                    period: "yr",
                    list: [
                        {
                            key: "Dwelling Coverage",
                            value: this.userHomeQuotesUpdateForm.value
                                .dwellingCoverage,
                        },
                        {
                            key: "Other Structures",
                            value: this.userHomeQuotesUpdateForm.value
                                .otherStructures,
                        },
                        {
                            key: "Personal Property",
                            value: this.userHomeQuotesUpdateForm.value
                                .personalProperty,
                        },
                        {
                            key: "Personal Liability",
                            value: this.userHomeQuotesUpdateForm.value
                                .lossOfUse,
                        },
                        {
                            key: "Med Pay",
                            value: this.userHomeQuotesUpdateForm.value.medPay,
                        },
                        {
                            key: "Deductible",
                            value: this.userHomeQuotesUpdateForm.value
                                .deductible,
                        },
                        {
                            key: "Policy Length",
                            value: this.userHomeQuotesUpdateForm.value
                                .policyLength,
                        },
                        {
                            key: "Note",
                            value: this.userHomeQuotesUpdateForm.value.note,
                        },
                    ],
                    Add_Ons: [
                        {
                            key: "Gun Coverage",
                            value: this.userHomeQuotesUpdateForm.value
                                .gunCoverage,
                            peroid: "yr",
                            status: false,
                        },
                        {
                            key: "Jewelry Coverage",
                            value: this.userHomeQuotesUpdateForm.value
                                .jewelleryCoverage,
                            peroid: "yr",
                            status: false,
                        },
                        {
                            key: "Collection Coverage",
                            value: this.userHomeQuotesUpdateForm.value
                                .collectionCoverage,
                            peroid: "yr",
                            status: false,
                        },
                    ],
                }),
            };
            this.policyService
                .updateUserQuotesService(
                    form_data,
                    this.userHomeQuotesUpdateForm.value.quotesId
                )
                .subscribe(
                    (response) => {
                        if (response) {
                            this.userHomeQuotesUpdateForm.reset();
                            this.spinner.hide();
                            this.getUserPolicyList(this.userId);
                            this.userPolicyUpdateFormSuccess =
                                "User's package updated successfully.";
                            this.modalService.dismissAll(this.modalRef);
                        }
                    },
                    (error) => {
                        console.log(error);
                        this.userPolicyUpdateFormError = error;
                        this.spinner.hide();
                    }
                );
        }
    }

    editPolicyClick(eachPolicyIndex, UpdatePolicyModel) {
        this.userPolicyIndexCount = 0;
        this.userPolicyUpdateFormError = "";
        this.userPolicyUpdateFormSuccess = "";
        this.userPolicyUpdateForm.patchValue({
            policyId: this.policyResponse[eachPolicyIndex].id,
            name: this.policyResponse[eachPolicyIndex].name,
        });
        this.userPolicyIndexCount = this.policyResponse[
            eachPolicyIndex
        ].packages.length;
        // console.log('userPolicyIndexCount', this.userPolicyIndexCount);
        this.modalRef = this.modalService
            .open(UpdatePolicyModel, { ariaLabelledBy: "modal-basic-title" })
            .result.then(
                (result) => {
                    // console.log('-- result', result);
                },
                (reason) => {
                    // console.log('-- reason', reason);
                }
            );
        // console.log('check');
    }

    updatePolicyClick() {
        this.userPolicyUpdateFormError = "";
        this.userPolicyUpdateFormSuccess = "";
        if (this.userPolicyUpdateForm.invalid) {
            this.userPolicyUpdateFormError =
                "Name field must be required and valid.";
        } else {
            this.spinner.show();
            let name = this.userPolicyUpdateForm.value.name;
            let policyId = this.userPolicyUpdateForm.value.policyId;
            this.policyService
                .updateUserPolicyService(name, policyId)
                .subscribe(
                    (response) => {
                        if (response) {
                            this.userQuotesForm.reset();
                            this.userQuotesForm.controls["policyType"].setValue(
                                ""
                            );
                            this.spinner.hide();
                            this.getUserPolicyList(this.userId);
                            this.userPolicyUpdateFormSuccess =
                                "User's policy updated successfully.";
                            this.modalService.dismissAll(this.modalRef);
                        }
                    },
                    (error) => {
                        console.log(error);
                        this.userPolicyUpdateFormError = error;
                        this.spinner.hide();
                    }
                );
        }
    }

    deletePolicyClick(policyId) {
        // console.log('check', policyId);
        if (confirm("Are you sure want delete this policy?")) {
            this.spinner.show();
            this.policyService.deleteUserPolicyService(policyId).subscribe(
                (response) => {
                    if (response) {
                        this.modalService.dismissAll(this.modalRef);
                        this.spinner.hide();
                        this.getUserPolicyList(this.userId, true);
                    }
                },
                (error) => {
                    console.log(error);
                    this.spinner.hide();
                }
            );
        }
    }

    // DRAG EVENT HANDLER

    // SCREEN DRAG CONFIG STARTS

    dragMoved(e: CdkDragMove, index) {
        this.policyIndex = index;
        let point = this.getPointerPositionOnPage(e.event);

        this.listGroup._items.forEach((dropList) => {
            if (__isInsideDropListClientRect(dropList, point.x, point.y)) {
                this.activeContainer = dropList;
                return;
            }
        });
    }

    dropListDropped(event) {
        if (!this.target) return;

        let phElement = this.placeholder.element.nativeElement;
        let parent = phElement.parentElement;

        phElement.style.display = "none";

        parent.removeChild(phElement);
        parent.appendChild(phElement);
        parent.insertBefore(
            this.source.element.nativeElement,
            parent.children[this.sourceIndex]
        );

        this.target = null;
        this.source = null;

        // if (this.sourceIndex != this.targetIndex)
        //     moveItemInArray(
        //         this.policyResponse[this.policyIndex].packages,
        //         this.sourceIndex,
        //         this.targetIndex
        //     );

        // this.updateDeviceIndex(
        //     this.policyIndex,
        //     this.sourceIndex,
        //     this.targetIndex
        // );
    }

    dropListEnterPredicate = (drag: CdkDrag, drop: CdkDropList) => {
        if (drop == this.placeholder) return true;

        if (drop != this.activeContainer) return false;

        let phElement = this.placeholder.element.nativeElement;
        let sourceElement = drag.dropContainer.element.nativeElement;
        let dropElement = drop.element.nativeElement;

        let dragIndex;
        let dropIndex;

        if (this.indexDetector === 1) {
            dragIndex =
                __indexOf(
                    dropElement.parentElement.children,
                    this.source ? phElement : sourceElement
                ) - 1;
            dropIndex =
                __indexOf(dropElement.parentElement.children, dropElement) - 1;
            // this.indexDetector += 1;
        } else {
            dragIndex = __indexOf(
                dropElement.parentElement.children,
                this.source ? phElement : sourceElement
            );
            dropIndex = __indexOf(
                dropElement.parentElement.children,
                dropElement
            );
        }

        if (!this.source) {
            this.sourceIndex = dragIndex;
            this.source = drag.dropContainer;

            phElement.style.width = sourceElement.clientWidth + "px";
            phElement.style.height = sourceElement.clientHeight + "px";

            sourceElement.parentElement.removeChild(sourceElement);
        }

        this.targetIndex = dropIndex;
        this.target = drop;

        phElement.style.display = "";
        dropElement.parentElement.insertBefore(
            phElement,
            dropIndex > dragIndex ? dropElement.nextSibling : dropElement
        );

        this.placeholder.enter(
            drag,
            drag.element.nativeElement.offsetLeft,
            drag.element.nativeElement.offsetTop
        );
        return false;
    };

    /** Determines the point of the page that was touched by the user. */
    getPointerPositionOnPage(event: MouseEvent | TouchEvent) {
        // `touches` will be empty for start/end events so we have to fall back to `changedTouches`.
        const point = __isTouchEvent(event)
            ? event.touches[0] || event.changedTouches[0]
            : event;
        const scrollPosition = this.viewportRuler.getViewportScrollPosition();

        return {
            x: point.pageX - scrollPosition.left,
            y: point.pageY - scrollPosition.top,
        };
    }

    // SAVE DEVICE INDEX
    setKeyValue(val?) {
        const devicesIndex = this.policyResponse.map((p) => {
            let obj = {
                id: p.id,
                data: p.packages.map((pck, i) => ({
                    entity_id: pck.id,
                    index: i,
                })),
            };
            return obj;
        });
        this.policyService
            .setKeyValue(JSON.stringify(devicesIndex))
            .subscribe((response) => {
                // console.log("SET KEY VALUE: ", response);
            });
    }

    getKeyValue() {
        this.policyService.getKeyValue().subscribe((response) => {
            if (response.status == "error") {
                this.policyResponse.filter((x) => {
                    this.devicesIndex.push({ id: x.id });
                    const index = this.devicesIndex.findIndex(
                        (y) => y.id == x.id
                    );
                    this.devicesIndex[index].data = [];
                    x.packages.filter((i, z) => {
                        this.devicesIndex[index].data.push({
                            entity_id: i.id,
                            index: z,
                        });
                    });
                });
            } else {
                // console.log(JSON.parse(response.item.data));
                let mapping = JSON.parse(response.item.data);
                let policies = [...this.policyResponse];

                mapping.forEach((map) => {
                    let temp = [];
                    let pIndex = policies.findIndex((p) => map.id === p.id);
                    let packages = policies[pIndex].packages;
                    console.log(packages, map.data);
                    packages.forEach((pck, i) => {
                        let x = map.data[i];
                        temp[x.index] = packages.find(
                            (p) => Number(p.id) === Number(x.entity_id)
                        );
                    });
                    this.policyResponse[pIndex].packages = [...temp];
                });

                // policies.forEach(policy => {
                //     let packages = policy.packages;
                //     let mapIndex = mapping.findIndex(m => m.id === policy.id);
                //     if(mapIndex >= 0){
                //         packages.forEach(p=> {
                //             let x = mapping[mapIndex].data;
                //             temp[x.index] = packages.find(
                //                             (p) => Number(p.id) === Number(x.entity_id)
                //                         );
                //             });
                //     }
                //     policy.packages = [...temp];
                // });

                // mapping.forEach((mVal) => {
                //     const index = this.policyResponse.findIndex(p => p.id === mVal.id);
                //     const packages = this.policyResponse[index].packages;
                //     packages.forEach((pck, i) => {
                //         console.log(pck, mVal.data);
                //         let x = mVal.data[i];
                //         temp[x.index] = packages.find(
                //             (p) => Number(p.id) === Number(x.entity_id)
                //         );
                //     });
                //     this.policyResponse[index].packages = [...temp];
                // });
            }
        });
    }

    // GET DEVICE INDEX
}

function __indexOf(collection, node) {
    return Array.prototype.indexOf.call(collection, node);
}

/** Determines whether an event is a touch event. */
function __isTouchEvent(event: MouseEvent | TouchEvent): event is TouchEvent {
    return event.type.startsWith("touch");
}

function __isInsideDropListClientRect(
    dropList: CdkDropList,
    x: number,
    y: number
) {
    const {
        top,
        bottom,
        left,
        right,
    } = dropList.element.nativeElement.getBoundingClientRect();
    return y >= top && y <= bottom && x >= left && x <= right;
}
