import { Component, OnInit , Output, EventEmitter} from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { HostListener } from "@angular/core";
import { HeaderService } from "./header.service";
import { NgxSpinnerService } from "ngx-spinner";
import { ClientsService } from "../pages/clients/clients.service";
import {environment} from 'src/environments/environment';
import {
    Location,
    LocationStrategy,
    PathLocationStrategy
} from "@angular/common";
import { Subject } from 'rxjs';

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],

    providers: [
        Location,
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class HeaderComponent implements OnInit {
    location: any;
    layoutClass: string;
    isDashboradHeader: boolean = false;
    isAdmin: boolean = false;
    notification= false;
    notificationCount:number = 0;
    unreadNotificationCount:number = 0;
    notificationArrayList:any = [];
    notificationArrayLimitStatus:boolean = true;
    viewMoreNotifyBtn:boolean = true;
    loginUserDetail:any = '';
    socket: any = '';
    @Output() _emitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    isNewNotification:boolean = false;

    constructor(private router: Router,
            location: Location,
            private route: ActivatedRoute,
            private headerService: HeaderService,
            private spinner: NgxSpinnerService,
            private clientsService: ClientsService
        ) {
        this.router.events.subscribe(ev => {
            if (ev instanceof NavigationEnd) {
                this.loginUserDetail = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')): '';
                this.location = location.path();
                if (this.location == "/demo-3") {
                    this.layoutClass = "navbar-style-two";
                } else {
                    this.layoutClass = "";
                }
                var final_url = this.location.split("/");
                // console.log(final_url);
                if(final_url[1] == 'dashboard'){
                    this.location = '/dashboard';
                }
                if (
                    ["/dashboard", "/clients-list", "/clients-detail","/user-policy", "/admin", "/policy", "/video", "/profile", "/prospects-list", "/loan-officers-list", "/real-estate-agents-list", "/company-reps-list", "/admin-announcements", "/agent-detail"].indexOf(this.location) != -1
                    ) {
                        this.isDashboradHeader = true;
                    } else {
                    this.isDashboradHeader = false;
                }
            }
        });


    }

    ngOnInit() {
        if(this.loginUserDetail == ''){
            this.loginUserDetail = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')): '';
        }
        // console.log('this.loginUserDetail', this.loginUserDetail);
        this.isAdmin = localStorage.getItem("isAdmin") ? true : false;
        // this.notificationList();

        var user = JSON.parse(localStorage.getItem('user'));
        var currentThis = this;
        if(user != null){
            var token = ( user.jwt != null) ? user.jwt : '';
            // console.log(token);
            if(token){
                this.socket = new WebSocket(`ws://${environment.apiIP}/cable?token=${token}`);
            }
        }
        // console.log('\n socket in 86 header', this.socket);
        if(this.socket != '' && this.socket != undefined){
            // Connection opened
            this.socket.addEventListener('open', function (event) {
                console.log('event calling');
                currentThis.socket.send(JSON.stringify({"command": "subscribe","identifier":"{\"channel\":\"WebNotificationChannel\"}"}));
            });

            // Listen for messages
            this.socket.addEventListener('message', function (event) {
                var data = JSON.parse(event.data);
                // console.log('\n \n event.data.message ------- ', data.message);
                if(data.identifier != undefined){
                    currentThis.notificationList();
                    // currentThis._emitter.emit(true);
                    currentThis.isNewNotification = true;
                }
                // console.log(event.data);
            });
        }

        this.headerService.moveOnFeedDiv.subscribe((feed_id:any) => {
            var elmnt = document.getElementById(feed_id);
            // console.log('feed_id in 122', feed_id);
            console.log('elmnt in 123', elmnt);
            if(elmnt != null){
                elmnt.scrollIntoView();
            }else{
                this.headerService.findPostForClick.next(feed_id);
            }
            // this.notificationList();
        })

    }

    @HostListener('window:click', ['$event.target'])
      handleKeyDown(event) {
          if(!event.matches('.notifications, .notifications *, .viewAllNotifyClass')) {
         this.notification = false;
      } else{
          this.notification = true;

      }
    }

    formatDate(date) {
        return this.headerService.formatDateFun(date);
        // return timeAgo.format(new Date(date));
    }

    notificationList(){
        // this.spinner.show();
        this.headerService.notificationListService()
        .subscribe(
            data => {
                if(data.status == 'ok'){
                    if(data.notifications.length !== undefined){
                        this.notificationCount = data.notifications.length;
                        this.unreadNotificationCount = data.unread_count;
                        if(this.notificationArrayLimitStatus){
                            this.notificationArrayList = data.notifications.slice(0,5);
                        }else{
                            this.notificationArrayList = data.notifications;
                        }
                    }
                }
                this.spinner.hide();
            },
            error => {
                console.log(error);
                this.spinner.hide();
            }
        );
    }

    viewAllNotify(){
        this.notificationArrayLimitStatus = false;
        this.viewMoreNotifyBtn = false;
        this.spinner.show();
        this.notificationList();
    }

    clickOnNotification(eachNotifyDetail){
        if(this.unreadNotificationCount > 0 && eachNotifyDetail.seen == false){
            this.unreadNotificationCount = this.unreadNotificationCount - 1;
        }
        eachNotifyDetail.seen = true;
        var url = this.router.url;
        var final_url = url.split("/");
        // console.log('eachNotifyDetail.object_id', eachNotifyDetail);
        var feed_id;
        if(eachNotifyDetail.object_type == 'post'){
            feed_id = eachNotifyDetail.object_id;
        }else if(eachNotifyDetail.object_type == 'user'){
            if(this.loginUserDetail.user_type != 0 && eachNotifyDetail.did_by.user_type == 0 ){
                this.clientsService.redirectToUserDataService(eachNotifyDetail.did_by);
                this.router.navigate(['/clients-detail']);
            }
        }else if(eachNotifyDetail.payload !== null){
            let feed_data = JSON.parse(eachNotifyDetail.payload);
            feed_id = feed_data.post_id;
        }else{

        }
        console.log('feed_id', feed_id);
        this.headerService.changeReadStatusNotifyService(eachNotifyDetail.id, true)
        .subscribe(
            data => {
                if(final_url[1] !== 'dashboard'){
                    localStorage.setItem('notify-id', feed_id);
                    this.router.navigate(['/dashboard']);
                }else{
                   this.headerService.moveOnFeedDiv.next(feed_id);
                }
            },
            error => {
                console.log(error);
            }
        );
    }



    clearAllNotify(){
        // console.log('test');
        this.spinner.show();
        this.headerService.clearAllNotifyService()
        .subscribe(
            data => {
                // console.log('response in 112', data);
                // this.spinner.hide();
                this.notificationList();
            },
            error => {
                console.log(error);
                this.spinner.hide();
            }
        );
    }

    deleteNotification(eachnotify){
        this.spinner.show();
        this.headerService.deleteNotificationService(eachnotify.id)
        .subscribe(
            data => {
                // console.log('response in 112', data);
                this.notificationList();
                this.spinner.hide();
            },
            error => {
                console.log(error);
                this.spinner.hide();
            }
        );
    }

    logoutFunc(){
        this.spinner.show();
        this.headerService.clearWebSocketToken()
        .subscribe(
            data => {
                if(data.status == 'ok'){
                    this.spinner.hide();
                    localStorage.clear();
                    this.loginUserDetail = '';
                    this.socket = '';
                    this.router.navigate(['/']);
                }
            },
            error => {
                console.log(error);
                this.spinner.hide();
                localStorage.clear();
                this.loginUserDetail = '';
                this.socket = '';
                this.router.navigate(['/']);
            }
        );
    }

    notificationShow(){
        this.notification = true;
        if(this.isNewNotification == true){
            this._emitter.emit(this.isNewNotification);
            this.headerService.newNotification.next(this.isNewNotification);
            this.isNewNotification = false;
        }
    }

    profile() {
        this.router.navigate([`/profile`])
    }
}
