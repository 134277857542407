import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { Dimensions, ImageCroppedEvent, ImageTransform } from './utils/index';
import {base64ToFile} from './utils/blob.utils';
import { DashboardService } from '../../pages/dashboard/dashboard.service';
import { Router, ActivatedRoute } from "@angular/router";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { PostSidebarService } from "../post-sidebar/post-sidebar.service";
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-upload-image-cropper',
  templateUrl: './upload-image-cropper.component.html',
  styleUrls: ['./upload-image-cropper.component.scss']
})
export class UploadImageCropperComponent implements OnInit, OnDestroy {
        @ViewChild("ImageUpload", { static: false }) ImageUpload;
        uploadProfileImageSubscription: Subscription;
        // image Upload
        imageChangedEvent: any = '';
        croppedImage: any = '';
        canvasRotation = 0;
        rotation = 0;
        scale = 1;
        showCropper = false;
        containWithinAspectRatio = false;
        transform: ImageTransform = {};
        userId;
        isImageUploadCallFrom;
        modalRef;

        constructor(
            private dashboardService: DashboardService,
            private spinner: NgxSpinnerService,
            private router: Router,
            private modalService: NgbModal,
            private postSidebarService: PostSidebarService
        ) { }

        ngOnInit() {

            this.uploadProfileImageSubscription = this.postSidebarService.uploadProfileImage
            .subscribe((data: any) => {
                this.userId = data.userId;
                this.isImageUploadCallFrom = data.isImageUploadCallFrom;
                this.openModel();
            });
        }

         /// Image Upload

        fileChangeEvent(event: any): void {
            this.imageChangedEvent = event;
        }

        imageCropped(event: ImageCroppedEvent) {
            // console.log("this.userId", this.userId);
            this.croppedImage = event.base64;
            // console.log('done base64', event, base64ToFile(event.base64));
        }

        imageLoaded() {
            this.showCropper = true;
            console.log('Image loaded');
        }

        cropperReady(sourceImageDimensions: Dimensions) {
            console.log('Cropper ready', sourceImageDimensions);
        }

        loadImageFailed() {
            console.log('Load failed');
        }

        rotateLeft() {
            this.canvasRotation--;
            this.flipAfterRotate();
        }

        rotateRight() {
            this.canvasRotation++;
            this.flipAfterRotate();
        }

        private flipAfterRotate() {
            const flippedH = this.transform.flipH;
            const flippedV = this.transform.flipV;
            this.transform = {
                ...this.transform,
                flipH: flippedV,
                flipV: flippedH
            };
        }


        flipHorizontal() {
            this.transform = {
                ...this.transform,
                flipH: !this.transform.flipH
            };
        }

        flipVertical() {
            this.transform = {
                ...this.transform,
                flipV: !this.transform.flipV
            };
        }

        resetImage() {
            this.scale = 1;
            this.rotation = 0;
            this.canvasRotation = 0;
            this.transform = {};
        }

        zoomOut() {
            this.scale -= .1;
            this.transform = {
                ...this.transform,
                scale: this.scale
            };
        }

        zoomIn() {
            this.scale += .1;
            this.transform = {
                ...this.transform,
                scale: this.scale
            };
        }

        toggleContainWithinAspectRatio() {
            this.containWithinAspectRatio = !this.containWithinAspectRatio;
        }

        updateRotation() {
            this.transform = {
                ...this.transform,
                rotate: this.rotation
            };
        }

        uploadImage(){
            this.spinner.show();
            const date = Date.now();
            let data = this.croppedImage.split(";");
            let fileType = data[0].split("data:")[1];
            let mimeType = data[0].split("/")[1];
            let base64 = data[1].split("base64,")[1];
            const obj = {
                filename: `${date}.${mimeType}`,
                content_type: fileType,
                data: base64
            };
            this.dashboardService
            .uploadAvtar(this.userId, obj)
            .subscribe(
                (response) => {
                    if (response) {
                        if (response["status"] === "ok") {
                            let user = JSON.parse(
                                localStorage.getItem("user")
                            );
                            user["avatar_url"] = response["avatar"].url;
                            localStorage.setItem(
                                "user",
                                JSON.stringify(user)
                            );
                            this.modalService.dismissAll(this.modalRef);
                            let redirect = '';
                            if(this.isImageUploadCallFrom == 'client'){
                                redirect = '/clients-detail';
                            }else{
                                redirect = '/dashboard';
                            }
                            this.router
                            .navigateByUrl("/RefreshComponent", {
                                skipLocationChange: true,
                            })
                            .then(() => {
                                this.router.navigate([redirect]);
                            });
                        }
                    }
                    this.spinner.hide();
                },
                (error) => {
                    console.log("error: ", error);
                    this.spinner.hide();
                }
            );
        }

        openModel(){
            this.modalRef = this.modalService
            .open(this.ImageUpload, { ariaLabelledBy: "modal-basic-title" })
            .result.then(
                (result) => {
                    // console.log('result', result);
                },
                (reason) => {}
            );
        }

        closeModel(){
            this.modalService.dismissAll(this.modalRef);
        }

        ngOnDestroy(){
            if(this.uploadProfileImageSubscription != undefined){
                this.uploadProfileImageSubscription.unsubscribe();
            }
        }

}
