import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { SlickCarouselModule } from "ngx-slick-carousel";
import { AngularMaterialModule } from "./common/angular-material/index.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { DefaultComponent } from "./demos/default/default.component";
import { FeedbackComponent } from "./common/feedback/feedback.component";
import { DemoTwoComponent } from "./demos/demo-two/demo-two.component";
import { DemoThreeComponent } from "./demos/demo-three/demo-three.component";
import { FunfactComponent } from "./common/funfact/funfact.component";
import { AboutComponent } from "./pages/about/about.component";
import { PartnerComponent } from "./common/partner/partner.component";
import { FeaturesComponent } from "./pages/features/features.component";
import { DownloadComponent } from "./common/download/download.component";
import { AccountButtonComponent } from "./common/account-button/account-button.component";
import { TalkButtonComponent } from "./common/talk-button/talk-button.component";
import { TeamComponent } from "./pages/team/team.component";
import { PricingComponent } from "./pages/pricing/pricing.component";
import { NotFoundComponent } from "./pages/not-found/not-found.component";
import { FaqComponent } from "./pages/faq/faq.component";
import { BlogComponent } from "./pages/blog/blog.component";
import { BlogDetailsComponent } from "./pages/blog-details/blog-details.component";
import { SignupComponent } from "./pages/signup/signup.component";
import { SigninComponent } from "./pages/signin/signin.component";
import { ContactComponent } from "./pages/contact/contact.component";
import { DashboardComponent } from "./pages/dashboard/dashboard.component";
import { LoaderComponent } from "./common/loader/loader.component";
import { HttpClientModule } from "@angular/common/http";
import { VideoComponent } from "./pages/video/video.component";
import { SidebarComponent } from "./pages/video/sidebar/sidebar.component";
import { MessagesComponent } from "./pages/video/messages/messages.component";
import { NavigationComponent } from "./pages/video/navigation/navigation.component";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { AdminComponent } from "./admin/admin.component";
import { ProfileComponent } from "./pages/profile/profile.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { PolicyComponent } from "./pages/policy/policy.component";
import { VerifyUserComponent } from "./pages/verify-user/verify-user.component";
import { DocumentsComponent } from "./common/documents/documents.component";
import { NgxSpinnerModule } from "ngx-spinner";
import { DateAgoPipe } from "./date-ago.pipe";
import { ClientsComponent } from "./pages/clients/clients.component";
import { AnnouncementDivComponent } from "./common/announcement-div/announcement-div.component";
import { AdminAnnouncementsComponent } from "./admin-announcements/admin-announcements.component";
import { CreateAnnouncementComponent } from "./create-announcement/create-announcement.component";
import { EditAnnouncementComponent } from "./edit-announcement/edit-announcement.component";
import { AnnouncementFormComponent } from "./common/announcement-form/announcement-form.component";
import { ClientDetailComponent } from "./pages/client-detail/client-detail.component";
import { NgxFileDropModule } from "ngx-file-drop";
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { UserPolicyComponent } from "./pages/user-policy/user-policy.component";
import { PolicyListComponent } from "./common/policy-list/policy-list.component";
import { TextMaskModule } from "angular2-text-mask";
import { MustMatchDirective } from "./pages/profile/must-match.directive";
import { TermConditionComponent } from "./pages/term-condition/term-condition.component";
import { PrivacyPolicyComponent } from "./pages/privacy-policy/privacy-policy.component";
import { UserListComponent } from "./common/user-list/user-list.component";
import { ProspectsListComponent } from "./pages/_user-list/prospects-list/prospects-list.component";
import { LoanOfficersListComponent } from "./pages/_user-list/loan-officers-list/loan-officers-list.component";
import { RealEstateAgentsListComponent } from "./pages/_user-list/real-estate-agents-list/real-estate-agents-list.component";
import { CompanyRepsListComponent } from "./pages/_user-list/company-reps-list/company-reps-list.component";
import { NgxPaginationModule } from "ngx-pagination";
import { ScrollDispatchModule } from "@angular/cdk/scrolling";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { AdminSigninComponent } from "./pages/admin-signin/admin-signin.component";
import { ImageCropperModule } from "ngx-image-cropper";
import { UploadImageCropperComponent } from "./common/upload-image-cropper/upload-image-cropper.component";
import { PostSidebarComponent } from "./common/post-sidebar/post-sidebar.component";
import { PostListComponent } from "./common/post-list/post-list.component";
import { AgentListComponent } from "./common/agent-list/agent-list.component";
import { AgentDetailModalComponent } from "./common/agent-detail-modal/agent-detail-modal.component";
import { AgentDetailComponent } from "./pages/agent-detail/agent-detail.component";
import { AddPostComponent } from "./common/add-post/add-post.component";
import { RegisterClientComponent } from "./common/register-client/register-client.component";

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        DefaultComponent,
        FeedbackComponent,
        DemoTwoComponent,
        DemoThreeComponent,
        FunfactComponent,
        AboutComponent,
        PartnerComponent,
        FeaturesComponent,
        DownloadComponent,
        AccountButtonComponent,
        TalkButtonComponent,
        TeamComponent,
        PricingComponent,
        RegisterClientComponent,
        NotFoundComponent,
        FaqComponent,
        BlogComponent,
        BlogDetailsComponent,
        SignupComponent,
        SigninComponent,
        AdminSigninComponent,
        ContactComponent,
        DashboardComponent,
        LoaderComponent,
        VideoComponent,
        SidebarComponent,
        MessagesComponent,
        NavigationComponent,
        AdminComponent,
        ProfileComponent,
        PolicyComponent,
        VerifyUserComponent,
        DocumentsComponent,
        DateAgoPipe,
        ClientsComponent,
        AnnouncementDivComponent,
        AdminAnnouncementsComponent,
        AnnouncementFormComponent,
        CreateAnnouncementComponent,
        EditAnnouncementComponent,
        ClientDetailComponent,
        UserPolicyComponent,
        PolicyListComponent,
        MustMatchDirective,
        TermConditionComponent,
        PrivacyPolicyComponent,
        UserListComponent,
        ProspectsListComponent,
        LoanOfficersListComponent,
        RealEstateAgentsListComponent,
        CompanyRepsListComponent,
        UploadImageCropperComponent,
        PostSidebarComponent,
        PostListComponent,
        AgentListComponent,
        AgentDetailComponent,
        AgentDetailModalComponent,
        AddPostComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SlickCarouselModule,
        BrowserAnimationsModule,
        AngularMaterialModule,
        FormsModule,
        HttpClientModule,
        NgbModule,
        MatDatepickerModule,
        ReactiveFormsModule,
        NgxSpinnerModule,
        NgxFileDropModule,
        InfiniteScrollModule,
        TextMaskModule,
        NgxPaginationModule,
        ScrollDispatchModule,
        DragDropModule,
        ImageCropperModule,
    ],
    providers: [],
    exports: [RegisterClientComponent],
    bootstrap: [AppComponent],
})
export class AppModule {}
