import { Injectable, EventEmitter } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {throwError, Observable} from 'rxjs';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ClientsService {

    private API_URL = environment.apiUrl;
    // $selectedClientDetail = new EventEmitter();
    $selectedClientDetail = new EventEmitter();

    constructor(private http: HttpClient) { }

    // HEADERS
    protected getRequestHeaders(): { headers: HttpHeaders | { [header: string]: string | string[]; } } {
        let headers;
        headers = new HttpHeaders({
        'Content-Type': 'application/json',
        'x-api-key': localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')).jwt : ''
        //'Access-Control-Allow-Origin': '*'
        });
        return {headers: headers};
    }

    public getClientListService() {
        let endpoint = `${this.API_URL}/users/list?user_type=0&per_page=1000&page=1`;
        return this.http.get<any>(endpoint, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        );
    }

    public getUserListService(user_type) {
        let endpoint = `${this.API_URL}/users/list?user_type=${user_type}&per_page=1000&page=1`;
        return this.http.get<any>(endpoint, this.getRequestHeaders())
        .pipe(
            catchError(err => {
            return throwError(err);
            })
        );
    }

    public redirectToUserDataService(eachClient) {
        localStorage.setItem('client', eachClient.id);
        // this.$selectedClientDetail = eachClient;
    }

}
