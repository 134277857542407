import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ProfileService } from "./profile.service";
import { FormGroup } from "@angular/forms";

@Component({
    selector: "app-profile",
    templateUrl: "./profile.component.html",
    styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
    user: any = {};
    isEditPassword: boolean = false;
    currentPassword: string = "";
    newPassword: string = "";
    confirmPassword: string = "";
    message: string = "";
    messageErr: string = "";
    loginUserDetail:any = "";
    isAdmin: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private profileService: ProfileService
    ) {

    }

        ngOnInit() {
            this.loginUserDetail = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : '';
            this.isAdmin = localStorage.getItem('isAdmin') ? true : false;
            if (!this.isAdmin)
                this.getProfile();
            else
                this.user = {
                    name: 'Joshua Bolin',
                    email: this.loginUserDetail.email,
                    location: 'US'
                }
        }

        getProfile() {
            this.profileService.getUserProfile(this.loginUserDetail.id).subscribe(
                (response) => {
                    if (response) {
                        if (response["status"] === "ok") {
                            this.user = response["user"];
                        }
                    }
                },
                (error) => {
                    console.log("error: ", error);
                }
            );
        }

        submitPassword(profileForm) {
            this.message = '';
            this.messageErr = '';
            // console.log(
            //     this.currentPassword,
            //     this.newPassword,
            //     this.confirmPassword
            // );
            const obj = {
                password: this.currentPassword,
                new_password: this.newPassword,
                password_confirmation: this.confirmPassword,
            };
            this.profileService
                .changePassword(this.user.id, obj)
                .subscribe((response) => {
                    if (response) {
                        if (response["status"] == "error") {
                            this.messageErr = "Current password does not match.";
                            // this.message = response["message"];
                        } else {
                            this.message = "Password changed successfully";
                            profileForm.form.reset();
                        }
                        setTimeout(() => {
                            this.message = "";
                            this.messageErr = '';
                        }, 3000);
                    }
                });
        }
}
