import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    ElementRef,
    ViewChild, Output, Input
} from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { DashboardService } from "../dashboard/dashboard.service";
import { Router, ActivatedRoute } from "@angular/router";
import { HeaderService } from "src/app/header/header.service";
import { PostListService } from "../../common/post-list/post-list.service";

declare let window: any;
declare let document: any;

@Component({
  selector: 'app-agent-detail',
  templateUrl: './agent-detail.component.html',
  styleUrls: ['./agent-detail.component.scss']
})
export class AgentDetailComponent implements OnInit {

    private _mobileQueryListener: () => void;
    calledFromPage = 'agent';
    userId: string;
    isSideBarOpen: boolean = true;
    mobileQuery: MediaQueryList;

    constructor(
        media: MediaMatcher,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private postListService: PostListService,
        private headerService: HeaderService,
    ) {
        this.mobileQuery = media.matchMedia("(max-width: 1240px)");
        this._mobileQueryListener = () => {};
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngOnInit() {

        this.userId = this.headerService.getUserId(this.calledFromPage);
        // console.log('this.userId', this.userId);

        if(this.userId === '' || this.userId === null || this.userId === undefined){
            this.router.navigate(["/dashboard"]);
        }

        this.checkSideBar();
    }

    checkSideBar() {
        this.isSideBarOpen = !this.mobileQuery.matches;
        this.changeDetectorRef.detectChanges();
    }

    onScroll(callBy){
        this.postListService.callPageScrollFun.next(callBy);
    }

    toogleSideBar(): void {
        this.isSideBarOpen = !this.isSideBarOpen;
    }

    OnDestroy(){

    }
}
