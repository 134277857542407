import { MediaMatcher } from "@angular/cdk/layout";
import { HostListener } from "@angular/core";
import $ from "jquery";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { NgxSpinnerService } from "ngx-spinner";
import { Router, ActivatedRoute } from "@angular/router";
import { HeaderService } from "src/app/header/header.service";
import { AppService } from "src/app/app.service";
import { ClientsService } from "src/app/pages/clients/clients.service";
import { AgentListService } from "src/app/common/agent-list/agent-list.service";
import { PostListService } from "src/app/common/post-list/post-list.service";
import { PostSidebarService } from "src/app/common/post-sidebar/post-sidebar.service";
import { UserStructure } from "src/app/common/post-sidebar/user-structure";
import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    ElementRef,
    ViewChild, Output, Input
} from "@angular/core";
import { Subscription } from 'rxjs';

declare let window: any;
declare let document: any;

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.scss']
})
export class PostListComponent implements OnInit, OnDestroy {
    @ViewChild("UpdatePostValue", { static: false }) UpdatePostValue;
    @ViewChild("commentArea", { static: false }) commentArea;
    @ViewChild("UpdateCommentValue", { static: false }) UpdateCommentValue;
    @ViewChild("suggestion", { static: false }) suggestion;

    @Input() calledFromPage;
    @Input() userId: any;
    @Input() user: UserStructure = {
        id: 0,
        name: 'N/A',
        email: 'N/A',
        phone: 'N/A',
        description: 'Working on the latest API integration.',
        avatar_url: '../../../../assets/img/user.png',
        followers: 10200,
        following: 1700,
        location: 'Los Angeles, CA',
        badges: ["Auto", "Home"],
        createdOn: "10/12/2015",
        subscribed: false,
        auto: 'N/A',
        home: 'N/A',
        product: 'N/A',
        user_type: 0,
        join_date: ''
    };

    newNotificationSubscription: Subscription;
    findPostForClickSubscription: Subscription;
    getAllPostObjSubscription: Subscription;
    callPageScrollFunSubscription: Subscription;
    userDetailSubscription: Subscription;
    findPostOnClickSubscription: Subscription;
    newPostSubmitSubscription: Subscription;

    current_page: number = 1;
    total_page: number = 1;
    per_page: number = 25;
    postLoading: boolean = false;
    commentLoading: boolean = false;

    newPost: string = "";
    editPostId;
    editPost;
    posts = [];
    suggestions = [];
    postImageIndex;
    postId;
    commenText = -1;
    replyOnCommentId: string = "";
    replyOnCommentBody: string = "";
    commentBody: string = "";
    addPostMentionUser = [];
    redirecTONoficationId: any;
    mentionCounter: number = 0;
    commentImage = {
        filename: "",
        content_type: "",
        data: "",
        isImage: "",
        lastObject: {
            value: "",
        },
    };
    started: any;
    postImage = {
        filename: "",
        content_type: "",
        data: "",
        isImage: "",
    };

    suggestedusers = [];
    commentId: string = "";
    editCommentBody: string = "";
    loginUserDetail: {
        user_type: number,
        admin: boolean,
        id: number
    };
    agentPermissionStatus: boolean = false;
    isAdmin: boolean = false;
    findFeedId:any;

    constructor(
        private postListService: PostListService,
        private spinner: NgxSpinnerService,
        private router: Router,
        private headerService: HeaderService,
        private elementRef:ElementRef,
        private route: ActivatedRoute,
        private modalService: NgbModal,
        private appService: AppService,
        private clientsService: ClientsService,
        private agentListService: AgentListService,
        private postSidebarService: PostSidebarService
    ) { }

    ngOnInit() {
        this.loginUserDetail = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : "";
        this.agentPermissionStatus = this.loginUserDetail.user_type == 1 || this.loginUserDetail.admin == true ? true : false;

        // Observable
        this.newNotificationSubscription = this.headerService.newNotification
        .subscribe((response) => {
            this.getNotification(event);
        });

        this.findPostForClickSubscription = this.headerService.findPostForClick
        .subscribe((feed_id) => {
            this.findFeedId = feed_id;
            this.onScroll('findPost');
        });

        this.getAllPostObjSubscription = this.postListService.getAllPostObj
        .subscribe(() => {
            this.getAllPosts();
        });

        this.callPageScrollFunSubscription = this.postListService.callPageScrollFun
        .subscribe((data) => {
            this.onScroll(data);
        });

        this.userDetailSubscription = this.postSidebarService.userDetail
        .subscribe((data: UserStructure) => {
            this.user = data;
        });

        this.findPostOnClickSubscription = this.headerService.findPostOnClick
        .subscribe(async (data) => {
            var array = this.posts;
            let findVal = await array.filter((each) => {
                if(each.id == this.findFeedId) {
                    return each.id;
                }
            });
            // console.log('findVal', findVal);
            if(findVal){
                // this.spinner.show();
                localStorage.setItem("notify-id", this.findFeedId);
                this.redirectOnDiv();
                // this.headerService.moveOnFeedDiv.next(this.findFeedId);
            }else{
                this.headerService.findPostForClick.next(this.findFeedId);
            }
        });

        this.newPostSubmitSubscription = this.postListService.newPostSubmit
        .subscribe((data) => {
            // console.log('data', data);
            this.posts.unshift(data);
        });

        this.getAllPosts();
    }

    redirectOnDiv(){
        this.redirecTONoficationId = localStorage.getItem("notify-id");
        var currentThis = this;
        if (
            this.redirecTONoficationId != null &&
            this.redirecTONoficationId != ""
        ) {
            // this.spinner.show();
            var interval_time_set = setInterval(function () {
                var elmnt = document.getElementById(
                    currentThis.redirecTONoficationId
                );
                if (elmnt != null) {
                    elmnt.scrollIntoView();
                    currentThis.redirecTONoficationId = null;
                    // currentThis.spinner.hide();
                    clearInterval(interval_time_set);
                    localStorage.removeItem("notify-id");
                }
            }, 1000);
        }
    }

    getAllPosts(): void {
        this.spinner.show();
        var total_page = this.per_page * this.current_page;
        this.postListService.getPosts(this.isAdmin, total_page, 1, this.userId, this.calledFromPage).subscribe(
            (response) => {
                if (response) {
                    if (response["status"] === "ok") {
                        this.posts = response["posts"];
                        // console.log('this.posts', this.posts);
                        this.total_page = response["total_pages"];
                        this.getPostComments();
                        this.redirectOnDiv();
                        this.spinner.hide();
                    }
                }
            },
            (error) => {
                console.log("error: ", error);
                this.spinner.hide();
            }
        );
    }

    uploadPostImage(event, postId) {
        if (event.target.files && event.target.files[0]) {
            this.spinner.show();
            const file = event.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
                const date = Date.now();
                if (typeof reader.result === "string") {
                    let data = reader.result.split(";");
                    let fileType = data[0].split("data:")[1];
                    let mimeType = data[0].split("/")[1];
                    let base64 = data[1].split("base64,")[1];
                    const obj = {
                        filename: `${date}.${mimeType}`,
                        content_type: fileType,
                        data: base64,
                        isImage: "",
                        url: "",
                    };
                    if (
                        fileType == "image/png" ||
                        fileType == "image/jpeg" ||
                        fileType == "image/jpg" ||
                        fileType == "image/gif"
                    ) {
                        obj.isImage = "image";
                    } else {
                        obj.isImage = "file";
                    }
                    this.postListService
                        .uploadPostImage(postId, obj)
                        .subscribe(
                            (response) => {
                                if (response) {
                                    if (response["status"] === "ok") {
                                        this.posts.map((x) => {
                                            if (x.id === postId) {
                                                obj.url = response["image"].url;
                                                x.attachments.push(obj);
                                            }
                                        });
                                        //this.getAllPosts();
                                    }
                                }
                                this.spinner.hide();
                            },
                            (error) => {
                                console.log("error: ", error);
                                this.spinner.hide();
                            }
                        );
                }
                // this.user.image = reader.result
            };
            reader.readAsDataURL(file);
        }
    }


    formatDate(date) {
        // console.log('date', date);
        return this.headerService.formatDateFun(date);
    }

    addComment(event, feed) {
        // this.spinner.show();
        if (
            this.commentArea.nativeElement.innerHTML.length > 0 ||
            event.innerHTML ||
            this.commentImage.filename != ""
        ) {
            this.commentLoading = true;
            this.appService.isBackDrop.next(true);
            var data = {
                post_id: feed.id,
                body:
                    event.innerHTML != ""
                        ? event.innerHTML
                        : this.commentArea.nativeElement.innerHTML,
                attachment: this.commentImage.filename
                    ? this.commentImage
                    : null,
            };
            if (
                (data.body.length > 1 && data.body != "") ||
                this.commentImage.filename != ""
            ) {
                this.postListService.addComment(data).subscribe(
                    (res: any) => {
                        if (feed.comments && feed.comments.length) {
                            feed.comments.push(res.comment);
                        } else {
                            feed.comments = [res.comment];
                        }

                        feed.comment_count = feed.comments.length;
                        event.innerHTML = "";
                        this.commentImage = {
                            filename: "",
                            content_type: "",
                            data: "",
                            isImage: "",
                            lastObject: {
                                value: "",
                            },
                        };
                        this.commentLoading = false;
                        this.appService.isBackDrop.next(false);
                        this.commenText = -1;
                        // this.spinner.hide();
                    },
                    (error) => {
                        this.appService.isBackDrop.next(false);
                        this.commentLoading = false;
                    }
                );
            } else {
                // this.spinner.hide();
                this.commentLoading = false;
                this.commenText = -1;
                this.appService.isBackDrop.next(false);
            }
        }
    }

    deletePost(id) {
        if (confirm("Are you sure want to delete this post?")) {
            // this.spinner.show();
            this.posts = this.posts.filter((x) => x.id != id);
            this.postListService.deletePost(id).subscribe((res) => {
                // this.getAllPosts();
                // this.spinner.hide();
            });
        }
    }

    editPostRequest(feed) {
        this.editPostId = feed.id;
        this.editPost = feed.body;
        // this.spinner.show();
        var currentThis = this;
        var timer = setInterval(function () {
            if (
                document.getElementById("UpdatePostValue").innerHTML !=
                undefined
            ) {
                document.getElementById("UpdatePostValue").innerHTML =
                    feed.body;
                clearInterval(timer);
            }
        }, 100);
    }

    updatePost(feed) {
        // this.spinner.show();
        // const index = this.posts.findIndex(x => x.id == feed.id);
        feed.body = this.UpdatePostValue.nativeElement.innerHTML;
        feed.rendered_body = this.UpdatePostValue.nativeElement.innerHTML;
        feed.is_edited = true;
        // feed.body = this.UpdatePostValue.nativeElement.innerHTML;
        this.postListService
            .updatePost(this.editPostId, {
                body: this.UpdatePostValue.nativeElement.innerHTML,
            })
            .subscribe(() => {
                this.editPost = "";
                this.editPostId = "";
            });
    }

    editComment(comment) {
        this.commentId = comment.id;
        // this.editCommentBody = comment.body;
        var currentThis = this;
        var timer = setInterval(function () {
            if (
                currentThis.UpdateCommentValue.nativeElement.innerHTML !=
                undefined
            ) {
                currentThis.UpdateCommentValue.nativeElement.innerHTML =
                    comment.body;
                clearInterval(timer);
                // currentThis.spinner.hide();
            }
        }, 1000);
    }

    getPostComments() {
        // this.spinner.show();
        var currentThis = this;
        // var total_length = this.posts.length - 1;
        // tslint:disable-next-line: forin
        if (this.posts.length > 0) {
            for (let postIndex in this.posts) {
                this.postListService
                    .getPostComments(this.posts[postIndex].id)
                    .subscribe(
                        (res) => {
                            if (res["status"] == "ok") {
                                this.posts[postIndex].comments =
                                    res["comments"];
                            }
                            // if(postIndex == total_length.toString()){
                            //     this.spinner.hide();
                            // }
                        },
                        (err) => {
                            console.log("\n err", err);
                        }
                    );
            }
        }
    }

    updateComment(comment) {
        // this.spinner.show();
        let data = {
            body: this.UpdateCommentValue.nativeElement.innerHTML,
        };
        comment.body = this.UpdateCommentValue.nativeElement.innerHTML;
        comment.is_edited = true;
        this.postListService
            .updateComment(this.commentId, data)
            .subscribe((response) => {
                if (response && response["status"] == "ok") {
                    this.commentId = "";
                    this.editCommentBody = "";
                    // this.getPostComments();
                    // this.spinner.hide();
                }
            });
    }

    cancelCommentUpdate() {
        this.commentId = "";
        this.editCommentBody = "";
    }

    deleteComment(comment, feed) {
        if (confirm("Are you sure want to delete this comment?")) {
            // this.spinner.show();
            this.postListService
                .deleteComment(comment.id)
                .subscribe((response) => {
                    if (response && response["status"] === "ok") {
                        if (this.posts.length > 0) {
                            this.posts.forEach((x) => {
                                if (x.id == feed.id && x.comments) {
                                    x.comments = x.comments.filter(
                                        (y) => y.id != comment.id
                                    );
                                    x.comment_count = x.comments.length;
                                }
                            });
                        }
                    }
                });
            // this.getPostComments();
            // this.spinner.hide();
        }
    }

    editPostImage(index, postId) {
        this.postImageIndex = index;
        this.postId = postId;
    }

    deleteImage() {
        this.spinner.show();
        this.postListService
            .deletePostImage(this.postImageIndex, this.postId)
            .subscribe((response) => {
                if (response && response["status"] === "ok") {
                    this.posts.map((x) => {
                        delete x.images[this.postImageIndex];
                    });
                    this.postImageIndex = null;
                    this.postId = null;
                    this.spinner.hide();
                }
            });
    }

    uploadCommentImage($event, commentArea) {
        const file = $event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const date = Date.now();
            if (typeof reader.result === "string") {
                let data = reader.result.split(";");

                let fileType = data[0].split("data:")[1];
                let mimeType = data[0].split("/")[1];
                let base64 = data[1].split("base64,")[1];
                this.commentImage = {
                    filename: `${date}.${mimeType}`,
                    content_type: fileType,
                    data: base64,
                    isImage: "image",
                    lastObject: commentArea,
                };
                if (
                    fileType == "image/png" ||
                    fileType == "image/jpeg" ||
                    fileType == "image/jpg" ||
                    fileType == "image/gif"
                ) {
                    this.commentImage.isImage = "image";
                } else {
                    this.commentImage.isImage = "file";
                }
            }
        };
        reader.readAsDataURL(file);
    }

    deletePostImage(index, post_id) {
        // this.spinner.show();
        this.postListService
            .deletePostImage(index, post_id)
            .subscribe((response) => {
                if (response && response["status"] === "ok") {
                    this.posts.map((x) => {
                        if (x.id === post_id) {
                            x.attachments.splice(index, 1);
                            x.images.splice(index, 1);
                        }
                    });
                }
                this.spinner.hide();
            });
    }

    deleteCommentImage(comment) {
        this.postListService
            .deleteCommentImage(comment.id)
            .subscribe((res) => {
                comment.attachment = null;
                comment.image_url = null;
            });
    }

    uploadFeedImage($event) {
        const file = $event.target.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const date = Date.now();
            if (typeof reader.result === "string") {
                let data = reader.result.split(";");

                let fileType = data[0].split("data:")[1];
                let mimeType = data[0].split("/")[1];
                let base64 = data[1].split("base64,")[1];
                this.postImage = {
                    filename: `${date}.${mimeType}`,
                    content_type: fileType,
                    data: base64,
                    isImage: "",
                };

                if (
                    fileType == "image/png" ||
                    fileType == "image/jpeg" ||
                    fileType == "image/jpg" ||
                    fileType == "image/gif"
                ) {
                    this.postImage.isImage = "image";
                } else {
                    this.postImage.isImage = "file";
                }
            }
        };
        reader.readAsDataURL(file);
    }

    togglePostStatus(post, status) {
        post.done = status ? false : true;
        if (!status)
            this.postListService.markDone(post.id).subscribe(
                (response) => {},
                (error) => {
                    console.log("error: ", error);
                }
            );
        else
            this.postListService.markOpen(post.id).subscribe(
                (response) => {},
                (error) => {
                    console.log("error: ", error);
                }
            );
    }

    keypres(event) {
        if (this.suggestion) {
            let rect = event.target.getBoundingClientRect();
            event.target.parentElement.appendChild(
                this.suggestion.nativeElement
            );
        }
    }

    keyup(event) {
        const value = event.target.innerHTML;
        const text = value.substring((value + event.key).lastIndexOf("@") + 1);
        if ([" ", "ENTER"].indexOf(event.key) != -1) {
            this.started = "";
            this.suggestedusers = [];
        }

        if (event.key == "@" || this.started || value.slice(-1) == "@") {
            this.started = event.target;
            if (text.length > 0) {
                this.postListService
                    .suggestedUsersForMention(
                        this.loginUserDetail.user_type,
                        this.loginUserDetail.id,
                        text
                    ).subscribe((res) => {
                        console.log(res["users"]);
                        this.suggestedusers = res["users"];
                    });
            } else {
                this.suggestedusers = [];
            }
        }
    }

    selectUser(user) {
        if (this.started) {
            const value = this.started.innerHTML;
            const text =
                value.substring(0, value.lastIndexOf("@") + 1) +
                `<a (click) = "redirectToUserFromMention(${user.id})" contentEditable="false" data-id='${user.id}'>${user.name}</a>`;
            this.started.innerHTML = text;
            // let select_value = { "name": user.name, "id" : user.id};
            // let check_obj = this.addPostMentionUser.find(obj => obj.id == select_value.id);
            // if(check_obj == undefined){
            //     this.addPostMentionUser.push(select_value);
            // }
            // console.log('this.addPostMentionUser', this.addPostMentionUser);
        }
        this.started = "";
        this.suggestedusers = [];
    }

    likePost(feed) {
        // this.spinner.show();
        this.editPostId = "";
        // this.posts = [];
        if (feed.liked) {
            this.postListService.unlikePost(feed.id).subscribe(() => {
                // this.getAllPosts();
                // this.spinner.hide();
                feed.like_count = feed.like_count - 1;
            });
        } else {
            this.postListService.likePost(feed.id).subscribe(() => {
                // this.getAllPosts();
                // this.spinner.hide();
                feed.like_count = feed.like_count + 1;
            });
        }
        feed.liked = feed.liked ? false : true;
    }
    @HostListener("window:click", ["$event.target"])
    handleKeyDown(event) {
        this.suggestedusers = [];
    }

    likeUnlikeComment(comment) {
        if (comment.liked) {
            this.postListService
                .likeUnlikeComment(comment.id, "unlike")
                .subscribe(() => {
                    comment.like_count = comment.like_count - 1;
                });
        } else {
            this.postListService
                .likeUnlikeComment(comment.id, "like")
                .subscribe(() => {
                    comment.like_count = comment.like_count + 1;
                });
        }
        comment.liked = comment.liked ? false : true;
    }

    replyOnComment(comment) {
        this.replyOnCommentId = comment.id;
    }

    cancelReplyOnComment() {
        this.replyOnCommentId = "";
    }

    replyOnCommentSubmit(comment, feed) {
        if (this.UpdateCommentValue.nativeElement.innerHTML.length > 0) {
            // console.log('comment', comment);
            var data = {
                post_id: comment.post_id,
                body: this.UpdateCommentValue.nativeElement.innerHTML,
            };
            // console.log(this.replyOnCommentBody);
            this.postListService.addComment(data).subscribe((res: any) => {
                feed.comments.push(res.comment);
                feed.comment_count = feed.comments.length;
                // this.getAllPosts();
                this.replyOnCommentId = "";
                this.replyOnCommentBody = "";
            });
        }
    }

    redirectToUser(eachClient) {
        console.log('this.loginUserDetail.user_type', this.loginUserDetail.user_type);
        console.log('eachClient.user_type', eachClient.user_type);
        console.log('eachClient.id', eachClient.id);

        if (this.loginUserDetail.user_type === 1) {
            if (eachClient.user_type !== 1) {
                this.clientsService.redirectToUserDataService(eachClient);
                this.router.navigate(['/clients-detail']);
            } else if (this.loginUserDetail.id === eachClient.id){
                this.router.navigate(['/profile']);
            } else if (this.loginUserDetail.id !== eachClient.id && eachClient.user_type === 1){
                localStorage.setItem('agent', eachClient.id);

                if (this.router.url === '/agent-detail') {
                    this.router
                    .navigateByUrl('/RefreshComponent', {
                        skipLocationChange: true,
                    })
                    .then(() => {
                        this.router.navigate(['/agent-detail']);
                    });
                } else {
                    this.router.navigate(['/agent-detail']);
                }
            }
        } else {
            if (this.loginUserDetail.id === eachClient.id) {
                this.router.navigate(['/profile']);
            } else if (this.loginUserDetail.id !== eachClient.id && eachClient.user_type === 1) {
                    this.agentListService.agentDetailOnClickByOther.next(eachClient);
            }
        }
    }

    redirectToUserFromMention(id) {
        console.log('id', id);
    }

    commentBoxShowHide(commentArea, feedIndex) {
        this.commenText = feedIndex;
        this.commentArea.nativeElement.innerHTML = commentArea.innerHTML;
        if (this.commentImage.lastObject != commentArea) {
            if (this.commentImage.lastObject.value != "") {
                this.commentImage.lastObject.value = "";
            }
            this.commentImage = {
                filename: "",
                content_type: "",
                data: "",
                isImage: "",
                lastObject: {
                    value: "",
                },
            };
        }
    }

    getNotification(event) {
        if (event) {
            this.current_page = 1;
            this.posts = [];
            this.getAllPosts();
        }
    }

    async onScroll(callBy) {
        if (this.total_page > this.current_page) {
            this.current_page = this.current_page + 1;
            this.spinner.show();
            await this.postListService
                .getPosts(this.isAdmin, this.per_page, this.current_page,this.userId, this.calledFromPage)
                .subscribe(
                    async (response) => {
                        if (response) {
                            if (response["status"] === "ok") {
                                this.posts = [
                                    ...this.posts,
                                    ...response["posts"],
                                ];
                                this.total_page = response["total_pages"];
                                await this.getPostComments();
                                if(callBy === 'findPost'){
                                    this.headerService.findPostOnClick.next(this.posts);
                                }
                            }
                            this.spinner.hide();
                        }
                    },
                    (error) => {
                        console.log("error: ", error);
                        this.spinner.hide();
                    }
                );
        }
    }

    ngOnDestroy(){
        if(this.newNotificationSubscription != undefined){
            this.newNotificationSubscription.unsubscribe();
        }

        if(this.findPostForClickSubscription != undefined){
            this.findPostForClickSubscription.unsubscribe();
        }

        if(this.getAllPostObjSubscription != undefined){
            this.getAllPostObjSubscription.unsubscribe();
        }

        if(this.callPageScrollFunSubscription != undefined){
            this.callPageScrollFunSubscription.unsubscribe();
        }

        if(this.userDetailSubscription != undefined){
            this.userDetailSubscription.unsubscribe();
        }

        if(this.findPostOnClickSubscription != undefined){
            this.findPostOnClickSubscription.unsubscribe();
        }

        if(this.newPostSubmitSubscription != undefined){
            this.newPostSubmitSubscription.unsubscribe();
        }

    }

}
