import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { RegisterClientComponent } from "./../../common/register-client/register-client.component";
import {
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
    ViewEncapsulation,
    ElementRef,
    ViewChild,
    Output,
    Input,
} from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { DashboardService } from "../dashboard/dashboard.service";
import { Router, ActivatedRoute } from "@angular/router";
import { HeaderService } from "src/app/header/header.service";
import { PostListService } from "../../common/post-list/post-list.service";
import { PostSidebarService } from "src/app/common/post-sidebar/post-sidebar.service";
import { UserStructure } from "src/app/common/post-sidebar/user-structure";
import { Subscription } from "rxjs";

declare let window: any;
declare let document: any;

@Component({
    selector: "dashboard",
    templateUrl: "./dashboard.component.html",
    styleUrls: ["./dashboard.component.scss"],
    encapsulation: ViewEncapsulation.None,
})
export class DashboardComponent implements OnInit, OnDestroy {
    @ViewChild("registerClientModal", { static: false })
    registerClientModal: ElementRef;

    userDetailSubscription: Subscription;
    private _mobileQueryListener: () => void;
    calledFromPage = "dashboard";
    userId: string;
    isSideBarOpen: boolean = true;
    mobileQuery: MediaQueryList;
    user: UserStructure;

    constructor(
        media: MediaMatcher,
        private router: Router,
        private changeDetectorRef: ChangeDetectorRef,
        private headerService: HeaderService,
        private postListService: PostListService,
        private postSidebarService: PostSidebarService,
        private modalService: NgbModal
    ) {
        this.mobileQuery = media.matchMedia("(max-width: 1240px)");
        this._mobileQueryListener = () => {};
        this.mobileQuery.addListener(this._mobileQueryListener);
    }

    ngOnInit(): void {
        // console.log('loginUserDetail', this.loginUserDetail);

        this.userId = this.headerService.getUserId(this.calledFromPage);
        // console.log('this.userId', this.userId);

        if (
            this.userId === "" ||
            this.userId === null ||
            this.userId === undefined
        ) {
            this.router.navigate(["/"]);
        }

        this.userDetailSubscription = this.postSidebarService.userDetail.subscribe(
            (data: UserStructure) => {
                this.user = data;

                // update Local storage
                let user = JSON.parse(localStorage.getItem("user"));
                user["avatar_url"] = this.user.avatar_url;

                if (this.user.user_type !== user["user_type"]) {
                    user["user_type"] = this.user.user_type;
                    this.router
                        .navigateByUrl("/RefreshComponent", {
                            skipLocationChange: true,
                        })
                        .then(() => {
                            this.router.navigate(["/dashboard"]);
                        });
                }
                localStorage.setItem("user", JSON.stringify(user));
            }
        );

        this.checkSideBar();
    }

    checkSideBar() {
        this.isSideBarOpen = !this.mobileQuery.matches;
        this.changeDetectorRef.detectChanges();
    }

    onScroll(callBy) {
        this.postListService.callPageScrollFun.next(callBy);
    }

    toogleSideBar(): void {
        this.isSideBarOpen = !this.isSideBarOpen;
    }

    ngOnDestroy() {
        if (this.userDetailSubscription != undefined) {
            this.userDetailSubscription.unsubscribe();
        }
    }

    addClient(e) {
        this.modalService.open(this.registerClientModal, {});
        console.log(e.stopPropagation());
    }
}
