import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError, map } from "rxjs/operators";
import { throwError, Observable, Subject } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class AgentListService {
    private API_URL = environment.apiUrl;
    public agentDetailOnClickByOther = new Subject();

    constructor(private http: HttpClient) {}

    // HEADERS
    protected getRequestHeaders(): {
        headers: HttpHeaders | { [header: string]: string | string[] };
    } {
        let headers;
        headers = new HttpHeaders({
            "Content-Type": "application/json",
            "x-api-key": localStorage.getItem("user")
                ? JSON.parse(localStorage.getItem("user")).jwt
                : "",
            //'Access-Control-Allow-Origin': '*'
        });
        return { headers: headers };
    }

    public registerClient(body) {
        let endpoint = `${this.API_URL}/agents/register_user`;
        return this.http
            .post<any>(endpoint, { user: body }, this.getRequestHeaders())
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    public getAgentListService() {
        let endpoint = `${this.API_URL}/users/list?user_type=1&per_page=1000&page=1`;
        return this.http.get<any>(endpoint, this.getRequestHeaders()).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }
}
