import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { environment } from "../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class AdminService {
    private API_URL = environment.apiUrl;

    constructor(private http: HttpClient) {}

    public getUsers(current_page, per_page) {
        let endpoint = `${this.API_URL}/admin/users?per_page=${per_page}&page=${current_page}`;
        return this.http.get<any>(endpoint, this.getRequestHeaders()).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }

    public updateUser(id, data) {
        let endpoint = `${this.API_URL}/admin/users/${id}`;
        return this.http
            .put<any>(endpoint, data, this.getRequestHeaders())
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            );
    }

    // HEADERS
    protected getRequestHeaders(): {
        headers: HttpHeaders | { [header: string]: string | string[] };
    } {
        let headers;
        headers = new HttpHeaders({
            "Content-Type": "application/json",
            "x-api-key": localStorage.getItem("user")
                ? JSON.parse(localStorage.getItem("user")).jwt
                : "",
            //'Access-Control-Allow-Origin': '*'
        });
        return { headers: headers };
    }

    public getUsersTypeListService() {
        let endpoint = `${this.API_URL}/user_types`;
        return this.http.get<any>(endpoint, this.getRequestHeaders()).pipe(
            catchError((err) => {
                return throwError(err);
            })
        );
    }
}
