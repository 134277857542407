import { Component, OnInit, Input } from '@angular/core';
import { AdminAnnouncementService } from '../admin-announcement.service';

@Component({
  selector: 'app-edit-announcement',
  templateUrl: './edit-announcement.component.html',
  styleUrls: ['./edit-announcement.component.scss']
})
export class EditAnnouncementComponent implements OnInit {

    dummy:string = 'Dummy';

    @Input() loginUserDetail:any;

    announcementList:any = {
        "body": "",
        "id": "",
        "enabled": true
    };
    newNotiySubmited:any = false;
    announcementDetail:string = '';

    constructor(
        private adminAnnouncementService : AdminAnnouncementService
    ) {
    }

    ngOnInit() {
        this.getAdminAnnouncementList();
        // console.log('loginUserDetail in 17', this.announcementList);
    }

    addAnnouncementValueFun(value){
        this.adminAnnouncementService.addAnnouncement(value)
        .subscribe(
            response => {
                this.getAdminAnnouncementList();
            },
            error => {
                console.log("error: ", error);
            }
        );
    }

    editAnnouncementValueFun(value){
        this.adminAnnouncementService.updateAnnouncement(value)
        .subscribe(
            response => {
                this.getAdminAnnouncementList();
            },
            error => {
                console.log("error: ", error);
            }
        );
    }

    onDelete(){
        this.adminAnnouncementService.updateAnnouncement('')
        .subscribe(
            response => {
                this.getAdminAnnouncementList();
            },
            error => {
                console.log("error: ", error);
            }
        );
    }

    getAdminAnnouncementList(): void {
        this.adminAnnouncementService.getAdminAnnouncementListService().subscribe(
            response => {
                console.log('response', response);
                if (response.status === "ok") {
                    var list = response.announcements.reverse();
                    if(list.length > 0){
                        this.announcementList = list[0];
                        this.adminAnnouncementService.announcementId = this.announcementList.id;
                        this.announcementDetail = this.announcementList.body;
                    }else{
                        this.addAnnouncementValueFun('');
                    }


                }
            },
            error => {
                console.log("error: ", error);
            }
        );
    }

}
