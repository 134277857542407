import { Component, OnInit } from "@angular/core";
import { SigninService } from "./admin-signin.service";
import { Router } from "@angular/router";

@Component({
    selector: "app-admin-signin",
    templateUrl: "./admin-signin.component.html",
    styleUrls: ["./admin-signin.component.scss"],
})
export class AdminSigninComponent implements OnInit {
    email: string = "";
    password: string = "";
    loading: boolean = false;
    isSignInPage: boolean = true;
    error: string;

    constructor(private loginService: SigninService, private router: Router) {}

    ngOnInit() {
        let check_user_login = localStorage.getItem("user");
        if (check_user_login == undefined || check_user_login == "") {
            localStorage.clear();
        } else {
            this.router.navigate(["/dashboard"]);
        }
    }

    loginUser() {
        this.loading = true;
        // calling service to login user
        this.loginService
            .signinUser({ email: this.email, password: this.password })
            .subscribe(
                (response) => {
                    if (response) {
                        if (response.status === "ok") {
                            this.error = "";
                            localStorage.setItem(
                                "user",
                                JSON.stringify(response)
                            );
                            localStorage.setItem("isAdmin", "true");
                            this.router.navigate(["/admin"]);
                        } else {
                            this.error = response.message;
                        }
                        this.loading = false;
                    }
                },
                (error) => {
                    console.log(error);
                }
            );
    }
}
